<template>
  <v-container fluid class="full-d-flex flex-column">
    <v-card elevation="2" v-if="!isLoading">
      <v-card-title>
        <h3>Partner Details von {{ echtPartner.name_1 }}</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="ECHT-Partner-ID"
              v-model="echtPartner.ParentID"
              disabled
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-combobox
              label="Alias"
              v-model="echtPartner.short_name"
              outlined
              :items="partnerDetails.short_name"
              item-text="short_name"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-combobox
              label="Firmename"
              v-model="echtPartner.name_1"
              outlined
              :items="partnerDetails.name_1"
              item-text="name_1"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              label="Name 2"
              v-model="echtPartner.name_2"
              outlined
              :items="partnerDetails.name_2"
              item-text="name_2"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <h4>Accounts</h4>
        <v-divider></v-divider><br />
        <v-row>
          <v-col cols="11">
            <v-combobox
              label="Account - CRM ID"
              v-model="echtPartner.ACCOUNT_ID"
              outlined
              :items="partnerDetails.account_id"
              item-text="ACCOUNT_ID"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
          <v-col cols="1">
            <v-btn
              @click="redirectToCRM(echtPartner.account_id)"
              :disabled="echtPartner.ACCOUNT_ID == null"
              height="56px"
              width="100%"
            >
              <v-icon>mdi-microsoft-dynamics-365</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <h4>Adresse</h4>
        <v-divider></v-divider><br />
        <v-row>
          <v-col cols="6">
            <v-combobox
              label="Straße"
              v-model="echtPartner.street"
              outlined
              :items="partnerDetails.street"
              item-text="street"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              label="Ort"
              v-model="echtPartner.zip_city"
              outlined
              :items="partnerDetails.zip_city"
              item-text="zip_city"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-combobox
              label="Standort"
              v-model="echtPartner.Standort"
              outlined
              :items="partnerDetails.Standort"
              item-text="Standort"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              label="Land"
              v-model="echtPartner.country"
              outlined
              :items="partnerDetails.country"
              item-text="country"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <h4>Kontaktdaten</h4>
        <v-divider></v-divider><br />
        <v-row>
          <v-col cols="6">
            <v-combobox
              label="Telefon"
              v-model="echtPartner.phone"
              outlined
              :items="partnerDetails.phone"
              item-text="phone"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
          <v-col cols="6">
            <v-combobox
              label="E-Mail"
              v-model="echtPartner.mail"
              outlined
              :items="partnerDetails.mail"
              item-text="mail"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-combobox
              label="Website"
              v-model="echtPartner.homepage"
              outlined
              :items="partnerDetails.homepage"
              item-text="homepage"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <h4>Bankdaten</h4>
        <v-divider></v-divider><br />
        <v-row>
          <v-col cols="12">
            <v-combobox
              label="IBAN"
              v-model="echtPartner.iban"
              outlined
              :items="partnerDetails.iban"
              item-text="iban"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-combobox
              label="ILN - GLN"
              v-model="echtPartner.iln"
              outlined
              :items="partnerDetails.iln"
              item-text="iln"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
          <v-col cols="4">
            <v-combobox
              label="USt-ID"
              v-model="echtPartner.vat_id"
              outlined
              :items="partnerDetails.vat_id"
              item-text="vat_id"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
          <v-col cols="4">
            <v-combobox
              label="Steuernummer"
              v-model="echtPartner.tax_id"
              outlined
              :items="partnerDetails.tax_id"
              item-text="vat_id"
              item-value="PartnerID"
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row> </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn @click="openDialog"> Speichern </v-btn>
            <PartnerDetailsDialog
              @saveChanges="updatePartner(echtPartnerId)"
              ref="dialog"
            >
            </PartnerDetailsDialog>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div
      v-else
      class="d-flex flex-column justify-center align-center full-height"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="orange"
        indeterminate
      ></v-progress-circular>
    </div>
    <popupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
    </popupAlert>
  </v-container>
</template>

<script>
import apiPartnerService from "@/services/api/apiPartnerService";

import PartnerDetailsDialog from "../../dialogs/partnerApp/PartnerDetailsDialog.vue";
import popupAlert from "../../popupAlert.vue";

export default {
  data() {
    return {
      echtPartner: null,
      partnerDetails: null,
      mergedPartner: null,
      isLoading: true,
      alertType: "error",
      alertMessage: "",
    };
  },
  components: {
    PartnerDetailsDialog,
    popupAlert,
  },
  props: {
    echtPartnerId: Number,
  },
  methods: {
    async updatePartner() {
      const {
        short_name,
        name_1,
        name_2,
        name_3,
        street,
        zip_city,
        ACCOUNT_ID,
        Standort,
        country,
        phone,
        mail,
        homepage,
        iban,
        iln,
        vat_id,
        tax_id,
      } = this.echtPartner;

      const newPartner = {
        short_name,
        name_1,
        name_2,
        name_3,
        street,
        zip_city,
        account_id: ACCOUNT_ID,
        Standort,
        country,
        phone,
        mail,
        homepage,
        iban,
        iln,
        vat_id,
        tax_id,
      };

      try {
        const response = await apiPartnerService.updateEchtPartnerById(
          this.echtPartnerId,
          newPartner
        );
        this.isLoading = true;

        if (response.status === 200) {
          this.newAlert("Partner erfolgreich aktualisiert", "success");
          setTimeout(() => this.$emit("updatePartner"), 1000);
        } else {
          this.isLoading = false;
          this.newAlert("Fehler beim Aktualisieren des Partners", "error");
        }
      } catch (error) {
        this.isLoading = false;
        this.newAlert(
          `Fehler beim Aktualisieren des Partners: ${error}`,
          "error"
        );
      }
    },
    async fetchPartnerData(echtPartnerId) {
      this.isLoading = true;
      this.echtPartner = await apiPartnerService.getEchtPartnerById(
        echtPartnerId
      );
      this.$log.debug("Rescived Partner:", this.echtPartner);
      this.partnerDetails = await apiPartnerService.getEchtPartnerDetailsById(
        echtPartnerId
      );
      this.$log.debug("Rescived Partner Details:", this.partnerDetails);
      this.isLoading = false;
    },
    openDialog() {
      this.$refs.dialog.openDialog(this.echtPartner);
    },
    newAlert(message, type) {
      this.alertMessage = message;
      this.alertType = type;
      this.$refs.alert.newAlert();
    },
    redirectToCRM(ACCOUNT_ID) {
      //TODO: put in env file
      //window.location.href = `https://echt.crm4.dynamics.com/main.aspx?appid=81ce95b5-b5cd-4c71-9783-bf65f4a8380c&forceUCI=1&pagetype=entityrecord&etn=account&id=${ACCOUNT_ID}`;
      //open in new tab
      window.open(
        `https://echt.crm4.dynamics.com/main.aspx?appid=81ce95b5-b5cd-4c71-9783-bf65f4a8380c&forceUCI=1&pagetype=entityrecord&etn=account&id=${ACCOUNT_ID}`,
        "_blank"
      );
    },
  },
  mounted() {
    if (this.echtPartnerId) {
      this.fetchPartnerData(this.echtPartnerId);
    } else {
      this.$alert({
        title: "Fehler - Keine Partner ID gefunden",
        text: "Es wurde keine Partner ID gefunden",
        dialogMaxWidth: 404,
        acceptText: "Schliessen",
      });
      this.$router.go(-1);
      this.$log.error("No Partner ID found");
    }
  },
};
</script>

<style scoped>
.full-height {
  height: calc(100vh - 180px);
}
</style>
