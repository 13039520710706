<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="echtPartner"
      item-key="name"
      fixed-header
      :height="computedMaxHeight"
      dense
      :search="search"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <input type="checkbox" v-model="selected" :value="item" />
          </td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  dark
                  class="ma-1 text-h6"
                  v-bind="attrs"
                  v-on="on"
                  @click="copyToClipboard(item.SOURCE_UNIQUE_ID, 'source_id')"
                >
                  {{ splitSourceId(item.SOURCE_UNIQUE_ID, 0) }}
                </span>
              </template>
              <span>
                {{ splitSourceId(item.SOURCE_UNIQUE_ID, 1) }}
              </span>
            </v-tooltip>
          </td>
          <td>{{ item.short_name }}</td>
          <td>
            <v-icon v-if="item.ACCOUNT_ID" color="primary" dark
              >mdi-check</v-icon
            >
            <v-icon v-else color="error" dark>mdi-close</v-icon>
          </td>
          <td>{{ item.ECHT_PARTNER_ID }}</td>
          <td>{{ item.Standort }}</td>
          <td>
            <div :class="styles['chip-container']">
              <v-chip
                v-if="item.name_1"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.name_1 }}
              </v-chip>
              <v-chip
                v-if="item.name_2"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.name_2 }}
              </v-chip>
              <v-chip
                v-if="item.name_3"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.name_3 }}
              </v-chip>
            </div>
          </td>
          <td>
            <span>
              {{ item.country }}, {{ item.zip_city }}, {{ item.street }}
            </span>
          </td>
          <td>
            <div :class="styles['chip-container']">
              <v-chip
                v-if="item.homepage"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.homepage }}
              </v-chip>
              <v-chip
                v-if="item.mail"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.mail }}
              </v-chip>
              <v-chip
                v-if="item.phone"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.phone }}
              </v-chip>
            </div>
          </td>
          <td>
            <div :class="styles['chip-container']">
              <v-chip
                v-if="item.iln"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.iln }}
              </v-chip>
              <v-chip
                v-if="item.tax_id"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.tax_id }}
              </v-chip>
              <v-chip
                v-if="item.vat_id"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.vat_id }}
              </v-chip>
            </div>
          </td>
          <td>
            <div :class="styles['chip-container']">
              <v-chip
                v-if="item.iban"
                outlined
                small
                class="ma-1 centered-text"
                color="primary"
                dark
              >
                {{ item.iban }}
              </v-chip>
            </div>
          </td>
          <td>
            <v-icon v-if="item.Dirty === 0" color="green" dark>mdi-flag</v-icon>
            <v-icon v-else color="error" dark>mdi-flag</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import apiPartnerService from "@/services/api/apiPartnerService";

import styles from "@/styles/internal/PartnerAppView.module.scss";

const emitter = require("tiny-emitter/instance");

export default {
  props: {
    search: {
      type: String,
      default: "",
    },
    maxHeight: {
      type: String,
      default: "calc((100vh - 300px) / 2)",
    },
  },
  data() {
    return {
      selected: [],
      echtPartner: [],
      headers: [
        { text: "", value: "" },
        { text: "", value: "SOURCE_UNIQUE_ID" }, // statt tech
        { text: "Kurzname", value: "short_name" },
        { text: "CRM", value: "crm" },
        { text: "EchtId", value: "ECHT_PARTNER_ID" },
        { text: "Standort", value: "Standort" },
        { text: "Namen", value: "names" },
        { text: "Anschrift", value: "address" },
        { text: "Kontakt", value: "contact" },
        { text: "Steuer", value: "steuer" },
        { text: "Bank", value: "bank" },
        { text: "Bestätigt", value: "actions" },
      ],
      loading: false,
      styles,
    };
  },
  computed: {
    computedMaxHeight() {
      return this.maxHeight;
    },
  },
  watch: {
    selected(val) {
      this.$emit("selectChild", val);
    },
  },
  methods: {
    copyToClipboard(value, type) {
      if (type === "source_id") {
        navigator.clipboard.writeText(value.substring(2));
      } else {
        navigator.clipboard.writeText(value);
      }
    },
    splitSourceId(item, element) {
      switch (element) {
        case 0:
          return item.charAt(0);
        case 1:
          return item.substring(2);
        default:
          return item;
      }
    },
    async getPartner() {
      this.loading = true;
      try {
        this.echtPartner =
          await apiPartnerService.getSingleEchtPartnerParentsById();
        this.$log.debug("echtPartner", this.echtPartner);
      } catch (error) {
        this.$alert({
          title: "Fehler",
          text: "Fehler beim Laden der Partner",
          dialogMaxWidth: 400,
          acceptText: "Schliessen",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getPartner();
    emitter.on("partnerMerged", () => {
      this.selected = [];
      this.$emit("selectChild", this.selected);
      this.echtPartner = [];
      this.getPartner();
    });
  },
};
</script>
