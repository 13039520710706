import Vue from "vue";
import api from "../ApiBase";

export default {
  /**
   * @param {number} id
   * @returns {object} partner
   */
  async getEchtPartnerById(id) {
    try {
      Vue.$log.debug("Get EchtPartner by ID: " + id);
      let response = await api.get("GetEchtPartner?PartnerID=" + id);
      return response.data;
    } catch (error) {
      Vue.$log.error("Error in getEchtPartnerById: " + error);
      return null;
    }
  },
  /**
   * @param {number} id
   * @returns {object} partner parent
   */
  async getEchtPartnerDetailsById(id) {
    try {
      Vue.$log.debug("Get EchtPartnerDetails by ID: " + id);
      let response = await api.get("GetEchtPartnerDetails?PartnerID=" + id);
      return response.data;
    } catch (error) {
      Vue.$log.error("Error in getEchtPartnerDetailsById: " + error);
      return null;
    }
  },
  /**
   * @param {number} id
   * @param {object} partner
   * @returns {object} partner
   */
  async updateEchtPartnerById(id, partner) {
    try {
      Vue.$log.debug(
        "Update EchtPartner by ID: " +
        id +
        " with data: " +
        JSON.stringify(partner)
      );
      return await api.patch("UpdateEchtPartner?PartnerID=" + id, partner);
    } catch (error) {
      Vue.$log.error("Error in updateEchtPartnerById: " + error);
      return null;
    }
  },
  /**
   * @param {number} id
   * @returns {object} partner
   */
  async getEchtPartnerChildsById(id) {
    try {
      Vue.$log.debug("Get EchtPartnerChilds by ID: " + id);
      let response = await api.get("/GetEchtPartnerChilds?parentId=" + id);
      return response.data;
    } catch (error) {
      Vue.$log.error("Error in getEchtPartnerChildsById: " + error);
      return null;
    }
  },
  /**
   * @returns {object[]} partner
   */
  async getSingleEchtPartnerParentsById() {
    try {
      Vue.$log.debug("Get Single EchtPartnerParents");
      let response = await api.get("/GetEchtPartnerSingleParents");
      return response.data;
    } catch (error) {
      Vue.$log.error("Error in getSingleEchtPartnerParentsById: " + error);
      return null;
    }
  },
};
