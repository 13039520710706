import Vue from "vue";
import api from "../ApiBase";

export default {
  /**
   * @param {number} id
   * @returns {object} weather
   */
  async GetCurrentWeatherById(id) {
    try {
      Vue.$log.debug("Get Current Weather by ID: " + id);
      let response = await api.get("GetWeather?echtId=" + id + "&type=current");
      return response.data;
    } catch (error) {
      Vue.$log.error("Error in GetCurrentWeatherById: " + error);
      return null;
    }
  },
  /**
   * @param {number} id
   * @returns {object} weather forecast
   */
  async GetWeatherForecastById(id) {
    try {
      Vue.$log.debug("Get Weather Forecast by ID: " + id);
      let response = await api.get(
        "GetWeather?echtId=" + id + "&type=forecast"
      );
      return response.data;
    } catch (error) {
      Vue.$log.error("Error in GetWeatherForecastById: " + error);
      return null;
    }
  },
};
