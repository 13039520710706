//TODO Loading bar is not working

<template>
    <v-container fluid>
        <v-data-table 
            :headers="headers" 
            :items="item" 
            :items-per-page="10" 
            :loading="this.loading"
            :options.sync="tableOptions"
            :footer-props="{
                'items-per-page-options': [10, 25, 50, 75]
            }" 
            class="elevation-1" 
            show-select 
            fixed-header  
            height="calc((100vh - 100px) / 2" 
            loading-text="Bitte Warten"
            @toggle-select-all="selectAll($event)">
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <v-simple-checkbox :value="item.isSelected" @input="select(item)"></v-simple-checkbox>
                    </td>
                    <td>
                        <div style="padding: 0 ">
                            <v-col style="padding: 0">
                                <p class="font-weight-bold" margin="0" padding="0" style="margin-bottom: 0; font-size: 11px;">
                                    {{ item.item_description }}
                                </p>
                            </v-col>
                            <div v-if="item.expand">
                                <v-col style="padding: 0">
                                    <p class="font-italic font-weight-medium" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        Bereinigte Artikel-Beschreibung:
                                    </p>
                                    <p class="font-italic font-weight" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        {{ item.cleaned_item_description }}
                                    </p>
                                </v-col>
                                <v-col style="padding: 0">
                                    <p class="font-italic font-weight-medium" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        Artikel-ID:
                                    </p>
                                    <p class="font-italic font-weight" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        {{ item.item_id }}
                                    </p>
                                </v-col>
                                <v-col style="padding: 0">
                                    <p class="font-italic font-weight-medium" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        Artikel-No:
                                    </p>
                                    <p class="font-italic font-weight" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        {{ item.item_no }}
                                    </p>
                                </v-col>
                                <v-col style="padding: 0">
                                    <p class="font-italic font-weight-medium" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        Lieferant:
                                    </p>
                                    <p class="font-italic font-weight" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        {{ item.creditor_name }}
                                    </p>
                                </v-col>
                                <v-col style="padding: 0">
                                    <p class="font-italic font-weight-medium" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        Kategorie:
                                    </p>
                                    <p class="font-italic font-weight" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        {{ item.upper_categorization }}
                                    </p>
                                </v-col>
                                <v-col style="padding: 0">
                                    <p class="font-italic font-weight-medium" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        Tags:
                                    </p>
                                    <p class="font-italic font-weight" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        {{ item.tags }}
                                    </p>
                                </v-col>
                                <v-col style="padding: 0">
                                    <p class="font-italic font-weight-medium" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        Einheit:
                                    </p>
                                    <p class="font-italic font-weight" style="margin-bottom: 0; margin-left: 2px; font-size: 11px;"
                                        margin="0">
                                        {{ item.item_unit }}
                                    </p>
                                </v-col>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div v-if="item.gpc.level_1_code && item.itemEdit">
                            <v-combobox v-model="item.gpc.level_1_title" :items="gpc.level_1" item-text="level_1_title"
                                item-value="level_1_code" label="GPC-Level-1" clearable style="font-size: 11px;"
                                @change="updateGPCLevel($event, item, 1)" >
                            </v-combobox>
                        </div>
                        <div v-else-if="item.gpc.level_1_code && !item.itemEdit && item.status">
                            <v-col style="padding: 0">
                                <p class="font-weight-medium" style="margin-bottom: 0; font-size: 11px; margin-left: 2px" margin="0">
                                    {{ item.gpc.level_1_title }}
                                </p>
                            </v-col>
                        </div>
                        <div v-else-if="!item.level_1_code && item.status">
                            <v-combobox v-model="item.gpc.level_1_title" :items="gpcLocal.level_1"
                                item-text="level_1_title" item-value="level_1_code" label="GPC-Level-1" clearable
                                :disabled="item.status == true && item.itemEdit == false ? true : false" style="font-size: 11px"
                                @change="updateGPCLevel($event, item, 1)">
                            </v-combobox>
                        </div>
                        <div v-else-if="!item.level_1_code && !item.status" style="padding: 0px;">
                            <v-combobox v-model="item.gpc.level_1_title" :items="gpc.level_1" item-text="level_1_title"
                                item-value="level_1_code" label="GPC-Level-1" clearable 
                                hide-details="auto"
                                class="combobox-fontsize combobox-height"
                                @change="updateGPCLevel($event, item, 1)">
                            </v-combobox>
                        </div>
                    </td>

                    <td>
                        <div v-if="item.gpc.level_2_code && item.itemEdit">
                            <v-combobox v-model="item.gpc.level_2_title" :items="gpcLocal.level_2"
                                item-text="level_2_title" item-value="level_2_code" label="GPC-Level-2" clearable
                                style="font-size: 11px;"
                                @click="restrictChoosableGPCLevelWhenLevelIsChoosen(item, item.status, 2)"
                                @change="updateGPCLevel($event, item, 2)">
                            </v-combobox>
                        </div>
                        <div v-else-if="item.gpc.level_2_code && !item.itemEdit && item.status">
                            <v-col style="padding: 0">
                                <p class="font-weight-medium" style="margin-bottom: 0; font-size: 11px; margin-left: 2px" margin="0">
                                    {{ item.gpc.level_2_title }}
                                </p>
                            </v-col>
                        </div>
                        <div v-else-if="!item.level_2_code && item.status">
                            <v-combobox v-model="item.gpc.level_2_title" :items="gpcLocal.level_2"
                                item-text="level_2_title" item-value="level_2_code" label="GPC-Level-2" clearable
                                :disabled="item.status == true && item.itemEdit == false ? true : false" style="font-size: 11px;"
                                @change="updateGPCLevel($event, item, 2)">
                            </v-combobox>
                        </div>
                        <div v-else-if="!item.level_2_code && !item.status" style="padding: 0px;">
                            <v-combobox v-model="item.gpc.level_2_title" :items="gpcLocal.level_2"
                                item-text="level_2_title" item-value="level_2_code" label="GPC-Level-2" clearable
                                class="combobox-fontsize combobox-height"
                                hide-details="auto"
                                :disabled="item.itemComboboxRowDisabled"
                                @click="restrictChoosableGPCLevelWhenLevelIsChoosen(item, item.status, 2)"
                                @change="updateGPCLevel($event, item, 2)">
                            </v-combobox>
                        </div>
                    </td>

                    <td>
                        <div v-if="item.gpc.level_3_code && item.itemEdit" style="font-size: 11px;">
                            <v-combobox v-model="item.gpc.level_3_title" :items="gpcLocal.level_3"
                                item-text="level_3_title" item-value="level_3_code" label="GPC-Level-3" clearable
                                style="font-size: 11px;"
                                @click="restrictChoosableGPCLevelWhenLevelIsChoosen(item, item.status, 3)"
                                @change="updateGPCLevel($event, item, 3)">
                            </v-combobox>
                        </div>
                        <div v-else-if="item.gpc.level_3_code && !item.itemEdit && item.status" style="font-size: 11px;">
                            <v-col style="padding: 0">
                                <p class="font-weight-medium" style="margin-bottom: 0; margin-left: 2px" margin="0">
                                    {{ item.gpc.level_3_title }}
                                </p>
                            </v-col>
                        </div>
                        <div v-else-if="!item.level_3_code && item.status" style="font-size: 11px;">
                            <v-combobox v-model="item.gpc.level_3_title" :items="gpcLocal.level_3"
                                item-text="level_3_title" item-value="level_3_code" label="GPC-Level-3" clearable
                                style="font-size: 11px;"
                                :disabled="item.status == true && item.itemEdit == false ? true : false"
                                @change="updateGPCLevel($event, item, 3)">
                            </v-combobox>
                        </div>
                        <div v-else-if="!item.level_3_code && !item.status" style="padding: 0px;">
                            <v-combobox v-model="item.gpc.level_3_title" :items="gpcLocal.level_3"
                                item-text="level_3_title" item-value="level_3_code" label="GPC-Level-3" clearable
                                class="combobox-fontsize combobox-height"
                                hide-details="auto"
                                :disabled="item.itemComboboxRowDisabled"
                                @click="restrictChoosableGPCLevelWhenLevelIsChoosen(item, item.status, 3)"
                                @change="updateGPCLevel($event, item, 3)">
                            </v-combobox>
                        </div>
                    </td>
                    <td>
                        <div v-if="item.gpc.level_4_code && item.itemEdit" style="font-size: 11px;">
                            <v-combobox v-model="item.gpc.level_4_title" :items="gpcLocal.level_4"
                                item-text="level_4_title" item-value="level_4_code" label="GPC-Level-4" clearable
                                style="font-size: 11px;"
                                @change="updateGPCLevel($event, item, 4)"
                                @click="restrictChoosableGPCLevelWhenLevelIsChoosen(item, item.status, 4)">
                            </v-combobox>
                        </div>
                        <div v-else-if="item.gpc.level_4_code && !item.itemEdit && item.status" style="font-size: 11px;">
                            <v-col style="padding: 0">
                                <p class="font-weight-medium" style="margin-bottom: 0; margin-left: 2px" margin="0">
                                    {{ item.gpc.level_4_title }}
                                </p>
                            </v-col>
                        </div>
                        <div v-else-if="!item.level_4_code && item.status" style="font-size: 11px;">
                            <v-combobox v-model="item.gpc.level_4_title" :items="gpcLocal.level_4"
                                item-text="level_4_title" item-value="level_4_code" label="GPC-Level-4" clearable
                                style="font-size: 11px;"
                                :disabled="item.status == true && item.itemEdit == false ? true : false"
                                @change="updateGPCLevel($event, item, 4)">
                            </v-combobox>
                        </div>
                        <div v-else-if="!item.level_4_code && !item.status" style="padding: 0px;">
                            <v-combobox v-model="item.gpc.level_4_title" :items="gpcLocal.level_4"
                                item-text="level_4_title" item-value="level_4_code" label="GPC-Level-4" clearable
                                class="combobox-fontsize combobox-height"
                                hide-details="auto"
                                :disabled="item.itemComboboxRowDisabled"
                                @click="restrictChoosableGPCLevelWhenLevelIsChoosen(item, item.status, 4)"
                                @change="updateGPCLevel($event, item, 4)">
                            </v-combobox>
                        </div>
                    </td>
                    <td>
                        <div v-if="item.refundable">
                            <v-btn disabled dense @click="changeRefundable(item)">
                                <v-icon>mdi-cash-refund</v-icon>
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-btn disabled dense @click="changeRefundable(item)">
                                <v-icon>mdi-cash-off</v-icon>
                            </v-btn>
                        </div>
                    </td>
                    <td>
                        <div v-if="selectedTab == 0">
                            <v-btn dense @click="emitSaveFirstClassification(item)">
                                <v-icon color="#067800" dense> mdi-content-save-outline </v-icon>
                            </v-btn>
                        </div>
                        <div v-if="selectedTab == 1">
                            <v-btn dense @click="emitSaveCheck(item)">
                                <v-icon color="#067800" dense> mdi-check </v-icon>
                            </v-btn>
                        </div>
                        <div v-if="selectedTab == 2 && !item.itemEdit">
                            <v-btn dense disabled>
                                <v-icon color="#067800" dense> mdi-check </v-icon>
                            </v-btn>
                        </div>
                        <div v-if="selectedTab == 2 && item.itemEdit">
                            <v-btn dense @click="emitSaveEdit(item)">
                                <v-icon color="#067800" dense> mdi-check </v-icon>
                            </v-btn>
                        </div>
                    </td>
                    <td>
                        <div v-if="selectedTab == 0">
                            <v-btn :disabled="true">
                                <v-icon dense>mdi-pencil-off-outline</v-icon>
                            </v-btn>
                        </div>
                        <div v-else>
                            <div v-if="!item.itemEdit">
                                <v-btn @click="editItem(item)">
                                    <v-icon dense>mdi-pencil-outline</v-icon>
                                </v-btn>
                            </div>
                            <div v-else>
                                <v-btn @click="reverseEditItem(item)">
                                    <v-icon dense>mdi-restore</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </td>
                    <td>
                        <v-icon dense @click="changeExpand(item)">mdi-chevron-down</v-icon>
                    </td>
                </tr>
            </template>
            <template v-slot:expanded_row="{ item }">
                <v-col style="padding: 0">
                    <p class="font-italic font-weight" style="margin-bottom: 0; margin-left: 2px" margin="0">
                        Artikel-ID: {{ item.item_id }}
                    </p>
                </v-col>
                <v-col style="padding: 0">
                    <p class="font-italic font-weight" style="margin-bottom: 0; margin-left: 2px" margin="0">
                        Lieferant: {{ item.creditor_name }}
                    </p>
                </v-col>
            </template>
        </v-data-table>
        <PopupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </PopupAlert>
    </v-container>
</template>

<script>


import apiMixin from '@/mixins/apiMixin.js';
import PopupAlert from "@/components/popupAlert.vue";

export default {
    data() {
        return {
            alertType: 'error',
            alertMessage: '',
            selectedItems: [],
            gpcLocal: JSON.parse(JSON.stringify(this.gpc)),
            creditorIds: [],
            tmpItem: '',
            tmpGpc: [],
            tmpLevel2: [],
            tmpLevel3: [],
            iconDisabled: false,
            statusOptions: [true, false],
            item: [],
            creditor: [],
            loading: false,
            headers: [
                { text: 'Artikel', value: 'item_description',  width: "15%", sortable: true, align: 'start' },
                { text: 'GPC-Level-1', value: 'gpc.level_1_title', width: "13%", sortable: true, align: 'start' },
                { text: 'GPC-Level-2', value: 'gpc.level_2_title', width: "13%", sortable: true, align: 'start' },
                { text: 'GPC-Level-3', value: 'gpc.level_3_title', width: "13%", sortable: true, align: 'start' },
                { text: 'GPC-Level-4', value: 'gpc.level_4_title', width: "13%", sortable: true, align: 'start' },
                { text: 'Rückvergütung', value: '', width: "10px", sortable: false , align: 'start' },
                { text: 'Bestätigen', value: 'action', width: "10px", sortable: false , align: 'start' },
                { text: 'Bearbeiten', value: '', width: "5px", sortable: false , align: 'start' },
                { text: 'Infos', value: '', width: "5px", sortable: false , align: 'start' },
            ],
            tableOptions: {
                sortBy: ['item_description'], // Initialize with an empty array
            },
        }
    },

    mixins: [apiMixin],

    components: {
        PopupAlert
    },
    props: {
        gpc: {
            type: Object,
            default: () => { }
        },
        searchCreditor: {
            type: String,
            default: ''
        },
        searchDescription: {
            type: String,
            default: ''
        },
        searchId: {
            type: String,
            default: ''
        },
        searchIds: {
            type: Array,
            default: () => { }
        },
        searchGPCIds: {
            type: Array,
            default: () => { }
        },
        selectedTab: {
            type: Number,
            default: 0
        },
        selectedItemsForDeleting: {
            type: Array,
            default: () => { }
        }
    },
    methods: {
        /**
         * This function load all mapped or unmapped items dependent of the selected tab and
         * extend the items with further information of this component
         */
        async getItems() {
            try {
                this.loading = true;
                if (this.selectedTab == 0) {
                    return this.getUnmappedItems()
                }
                else if (this.selectedTab == 1) {
                    return this.getUncheckedMappedItems()
                }
                else if (this.selectedTab == 2){
                    return this.getCheckedMappedItems()
                }
                else {
                    this.newAlert(`Fehler beim Laden der Artikel `, 'error');
                }
            } catch (error) {
                console.log(error)
                this.newAlert(`Fehler beim Laden der Artikel `, 'error');
            }
        },
        /**
         * This function load all mapped items and extend the items with further information of this component
         */
        async getCheckedMappedItems() {
            await this.artikelAppApi.get('getCheckedMappedItems').then(response => {
                let items = response.data
                this.item = []
                items.forEach((item) => {
                    item = this.extendItem(item)
                    item = this.addGPCLevelFromLowestGPC(item)
                    this.item.push(item)
                })
            })
            this.loading = false;
        },
        /**
         * This function load all mapped items and extend the items with further information of this component
         */
         async getUncheckedMappedItems() {
            await this.artikelAppApi.get('getUncheckedMappedItems').then(response => {
                let items = response.data
                this.item = []
                items.forEach((item) => {
                    item = this.extendItem(item)
                    item = this.addGPCLevelFromLowestGPC(item)
                    this.item.push(item)
                })
            })
            this.loading = false;
        },
        /**
         * This function load all unmapped items and extend the items with further information of this component
         */
        async getUnmappedItems() {
            await this.artikelAppApi.get('getUnmappedItems').then(response => {
                let items = response.data
                this.item = []
                items.forEach((item) => {
                    this.item.push(this.extendItem(item))
                })
            })
            this.loading = false;
        },
        /**
         * This function load all mapped or unmapped items for an creditor_id dependent of the selected tab and
         * extend the items with further information of this component
         */
        async getItemsByCreditorId() {
            this.loading = true;
            this.item = []
            try {
                if (this.selectedTab == 0) {
                    this.artikelAppApi.get('getUnmappedItemsByCreditorId?tbit_echt_partner_id=' + this.searchCreditor).then(response => {
                        if (response.data != null) {
                            this.addInformationToUnmappedItemsForDisplay(response.data)
                        }
                    })
                }
                else if (this.selectedTab == 1){
                    this.artikelAppApi.get('getUncheckedMappedItemsByCreditorId?tbit_echt_partner_id=' + this.searchCreditor).then(response => {
                        if (response.data != null) {
                            this.addInformationToMappedItemsForDisplay(response.data)
                        }
                    })
                }
                else if (this.selectedTab == 2) {
                    this.artikelAppApi.get('getCheckedMappedItemsByCreditorId?tbit_echt_partner_id=' + this.searchCreditor).then(response => {
                        if (response.data != null) {
                            this.addInformationToMappedItemsForDisplay(response.data)
                        }
                    })
                }
                this.creditor = []
            }
            catch (error) {
                console.log(error)
                this.newAlert(`Fehler beim Laden mit der Lieferanten ID `, 'error');
            }
        },
        /**
         * This function load all mapped or unmapped items for an item_id dependent of the selected tab and
         * extend the items with further information of this component
         */
        async getItemByNo() {
            this.loading = true;
            this.item = []
            for (const id in this.searchIds) {
                try {
                    if (this.selectedTab == 0) {
                        await this.artikelAppApi.get('getUnmappedItemsByItemNo?item_no=' + this.searchIds[id]).then(response => {
                            let items = response.data.result
                            if (items != null) {
                                items.forEach((item) => {
                                    item = this.extendItem(item)
                                    this.item.push(item)
                                })
                            }
                        });
                    }
                    else if (this.selectedTab == 1) {
                        await this.artikelAppApi.get('getUncheckedMappedItemsByItemNo?item_no=' + this.searchIds[id]).then(response => {
                            let items = response.data
                            if (items != null) {
                                items.forEach((item) => {
                                    item = this.extendItem(item)
                                    item = this.addGPCLevelFromLowestGPC(item)
                                    this.item.push(item)
                                })
                            }
                        });
                    }
                    else if (this.selectedTab == 2) {
                        await this.artikelAppApi.get('getCheckedMappedItemsByItemNo?item_no=' + this.searchIds[id]).then(response => {
                            let items = response.data
                            if (items != null) {
                                items.forEach((item) => {
                                    item = this.extendItem(item)
                                    item = this.addGPCLevelFromLowestGPC(item)
                                    this.item.push(item)
                                })
                            }
                        });
                    }
                    else {
                        this.newAlert(`Fehler vom Laden der Artikel über die ID `, 'error');
                    }
                    this.loading = false;
                } catch (error) {
                    console.log(error)
                    this.newAlert(`Fehler vom Laden der Artikel über die ID `, 'error');
                }
            }
        },

        /**
         * This function load all mapped or unmapped items for an item_id dependent of the selected tab
         */
        async getItemById() {
            this.loading = true;
            this.item = []
            try {
                if (this.selectedTab == 0) {
                    await this.artikelAppApi.get('getUnmappedItemsByItemId?item_id=' + this.searchId).then(response => {
                        let items = response.data.result
                        if (items != null) {
                            items.forEach((item) => {
                                item = this.extendItem(item)
                                this.item.push(item)
                            })
                        }
                    });
                }
                else if (this.selectedTab == 1) {
                    await this.artikelAppApi.get('getUncheckedMappedItemsByItemId?item_id=' + this.searchId).then(response => {
                        let items = response.data
                        if (items != null) {
                            items.forEach((item) => {
                                item = this.extendItem(item)
                                item = this.addGPCLevelFromLowestGPC(item)
                                this.item.push(item)
                            })
                        }
                    });
                }
                else if (this.selectedTab == 2) {
                    await this.artikelAppApi.get('getCheckedMappedItemsByItemId?item_id=' + this.searchId).then(response => {
                        let items = response.data
                        if (items != null) {
                            items.forEach((item) => {
                                item = this.extendItem(item)
                                item = this.addGPCLevelFromLowestGPC(item)
                                this.item.push(item)
                            })
                        }
                    });
                }
                else {
                    this.newAlert(`Fehler vom Laden der Artikel über die ID `, 'error');
                }
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.newAlert(`Fehler vom Laden der Artikel über die ID `, 'error');
            }
        },


        /**
        * This function load all mapped or unmapped items for an item_description dependent of the selected tab and
        * extend the items with further information of this component
        */
        async getItemsByDescription() {
            this.loading = true;
            try {
                if (this.selectedTab == 0) {
                    await this.artikelAppApi.get('getUnmappedItemsByItemDescription?item_description=' + this.searchDescription).then(response => {
                        let items = response.data
                        this.item = []
                        if (items != null) {
                            items.forEach((item) => {
                                item = this.extendItem(item)
                                this.item.push(item)
                            })
                        }
                    })
                }
                else if (this.selectedTab == 1) {
                    await this.artikelAppApi.get('getUncheckedMappedItemsByItemDescription?item_description=' + this.searchDescription).then(response => {
                        let items = response.data
                        this.item = []
                        if (items != null) {
                            items.forEach((item) => {
                                item = this.extendItem(item)
                                item = this.addGPCLevelFromLowestGPC(item)
                                this.item.push(item)
                            })
                        }
                    })
                }
                else if (this.selectedTab == 2) {
                    await this.artikelAppApi.get('getCheckedMappedItemsByItemDescription?item_description=' + this.searchDescription).then(response => {
                        let items = response.data
                        this.item = []
                        if (items != null) {
                            items.forEach((item) => {
                                item = this.extendItem(item)
                                item = this.addGPCLevelFromLowestGPC(item)
                                this.item.push(item)
                            })
                        }
                    })
                }
                else {
                    this.newAlert(`Fehler vom Laden der Artikel über die Artikel Beschreibung `, 'error');
                }
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.newAlert(`Fehler vom Laden der Artikel über die Artikel Beschreibung `, 'error');
            }
        },
        /**
         * This function load all mapped or unmapped items for an gpc_id dependent of the selected tab and
         * extend the items with further information of this component
         */
        async getItemsByGPCIds() {
            try {
                const payload = { gpc_ids: this.searchGPCIds };
                if (this.selectedTab == 1) {
                    await this.artikelAppApi.post('getUncheckedMappedItemsForGPCIds', payload).then(response => {
                        let items = response.data
                        this.item = []
                        if (items != null) {
                            items.forEach((item) => {
                                item = this.extendItem(item)
                                item = this.addGPCLevelFromLowestGPC(item)
                                this.item.push(item)
                            })
                        }
                    })
                }
                else if (this.selectedTab == 2) {
                    await this.artikelAppApi.post('getCheckedMappedItemsForGPCIds', payload).then(response => {
                        let items = response.data
                        this.item = []
                        if (items != null) {
                            items.forEach((item) => {
                                item = this.extendItem(item)
                                item = this.addGPCLevelFromLowestGPC(item)
                                this.item.push(item)
                            })
                        }
                    })
                }
            } catch (error) {
                console.log(error)
                this.newAlert(`Fehler vom Laden der Artikel über die GPC-IDs`, 'error');
            }
        },

        /**
         * This function load all mapped or unmapped items for an given creditor_id and item_description dependent of the selected tab
         */
        async getItemsByCreditorIdAndItemDescription() {
            this.loading = true;
            try {
                await this.artikelAppApi.get('getCreditorIdsByPartnerId?tbit_echt_partner_id=' + this.searchCreditor).then(response => {
                    this.creditorIds = response.data
                })
                if (this.selectedTab == 0) {
                    await this.artikelAppApi.get('getUnmappedItemsByItemDescription?item_description=' + this.searchDescription).then(response => {
                        let items = response.data
                        this.item = []
                        if (items != null) {
                            items.forEach((item) => {
                                if (item.creditor_id == null) { return }
                                if (this.creditorIds.includes(item.creditor_id.toString())) { 
                                    item = this.extendItem(item)
                                    this.item.push(item)
                                }
                            })
                        }
                    })
                }
                else if (this.selectedTab == 1) {
                    await this.artikelAppApi.get('getUncheckedMappedItemsByItemDescription?item_description=' + this.searchDescription).then(response => {
                        let items = response.data
                        this.item = []
                        if (items != null) {
                            items.forEach((item) => {
                                if (item.creditor_id == null) { return }
                                if (this.creditorIds.includes(item.creditor_id.toString())) { 
                                    item = this.extendItem(item)
                                    item = this.addGPCLevelFromLowestGPC(item)
                                    this.item.push(item)
                                }
                            })
                        }
                    })
                }
                else if (this.selectedTab == 2) {
                    await this.artikelAppApi.get('getCheckedMappedItemsByItemDescription?item_description=' + this.searchDescription).then(response => {
                        let items = response.data
                        this.item = []
                        if (items != null) {
                            items.forEach((item) => {
                                if (item.creditor_id == null) { return }
                                if (this.creditorIds.includes(item.creditor_id.toString())) {  
                                    item = this.extendItem(item)
                                    item = this.addGPCLevelFromLowestGPC(item)
                                    this.item.push(item)
                                }
                            })
                        }
                    })
                }
                else {
                    this.newAlert(`Fehler vom Laden der Artikel über die Artikel Beschreibung `, 'error');
                }
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.newAlert(`Fehler vom Laden der Artikel über die Artikel Beschreibung `, 'error');
            }
            this.creditorIds = []
        },
        /**
         * When the tab is changing between mapped and unmapped itemss and the user had search one of the three option
         * not all items should be load, only these which the search identification
         */
        async getItemsAfterTabChange() {
            if ((this.searchCreditor != null) && (this.searchCreditor != undefined) && (this.searchCreditor != '')) {
                await this.getItemsByCreditorId();
                return
            }
            if ((this.searchDescription != null) && (this.searchDescription != undefined) && (this.searchDescription != '')) {
                await this.getItemsByDescription()
                return
            }
            if ((this.searchIds != "") && (this.searchIds!= null)){
                await this.getItemByNo()
                return
            }
            await this.getItems()
        },
        /**
         * For a given parent creditor of the user selected creditor in the combobox of the filterSearch View
         * get all ids of the children
         */
        async getChildCreditorIds() {
            try {
                await this.artikelAppApi.get('getCreditorChildrenIds?creditor_id=' + this.searchCreditor).then(response => {
                    response.data.child_id_list.forEach(child_id => {
                        if (child_id != null) {
                            this.creditor.push(child_id)
                        }
                    })
                })
            } catch (error) {
                console.log(error)
                this.newAlert(`Fehler vom Laden der Lieferanten Child-IDs`, 'error');
            }
        },
        /**
         * After loading the items, add the gpc level and extra information for showing in the data table
         * @param responseData 
         */
        addInformationToMappedItemsForDisplay(responseData) {
            if (responseData.length != 0) {
                responseData.forEach((item) => {
                    item = this.extendItem(item)
                    this.addGPCLevelFromLowestGPC(item)
                    this.item.push(item)
                })
            }
            else {
                this.item = []
            }
            this.loading = false;
        },
        /**
         * After loading the items, add the extra information for showing in the data table
         * @param responseData 
         */
        addInformationToUnmappedItemsForDisplay(responseData) {
            let itemsExtend = []
            if (responseData.length != 0) {
                responseData.forEach((item) => {
                    itemsExtend.push(this.extendItem(item))
                })
                this.item = itemsExtend
            }
            else {
                this.item = []
            }
            this.loading = false;
        },
        /**
         * Select a item to save it
         * @param item 
         */
        select(item) {
            item.isSelected = !item.isSelected
            if (item.isSelected) {
                this.selectedItems.push(item)
            } else {
                this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem !== item)
            }
            this.$emit('save_items', this.selectedItems)
        },
        /**
         * Select all shown items to save them
         * @param event 
         */
        selectAll(event) {
            if (event.value === true) {
                event.items.forEach(item => {
                    item.isSelected = true
                    this.selectedItems.push(item)
                })
                this.$emit('save_items', this.selectedItems)
                return
            }
            this.item.forEach(item => {
                item.isSelected = false
                this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem !== item)
            })
            this.$emit('save_items', this.selectedItems)
            this.selectedItems = []
        },
        /**
         * Save the first classification of the item
         * @param item 
         */
        emitSaveFirstClassification(item) {
            if(item.gpc.level_1_code == null || item.gpc.level_2_code == null || item.gpc.level_3_code == null || item.gpc.level_4_code == null){
                this.newAlert(`Bitte wählen Sie alle GPC-Level aus`, 'error');
                return
            }
            this.$emit('save_first_classification_item', item)
            this.item = this.item.filter(item_of_list => item_of_list.item_id !== item.item_id)
            this.gpcLocal = JSON.parse(JSON.stringify(this.tmpGpc))
            this.changeDisabledForItemRows(item, false)
        },
        /**
         * Save the checked item
         */
        emitSaveCheck(item) {
            this.$emit('save_check_item', item)
            this.item = this.item.filter(item_of_list => item_of_list.item_id !== item.item_id)
            this.gpcLocal = JSON.parse(JSON.stringify(this.tmpGpc))
        },
        /**
         * Save the edited item
         */
        emitSaveEdit(item) {
            this.$emit('save_edit_item', item)
            item.itemEdit = false
            this.gpcLocal = JSON.parse(JSON.stringify(this.tmpGpc))
        },
        /**
         * When in a row a combobox has a selected value, restrict the choice of the other comboboxes 
         * of the other rows
         * @param item 
         * @param disable 
         */
        changeDisabledForItemRows(item, disable) {
            if (disable) {
                this.item.forEach(i => {
                    if (i.item_id != item.item_id) {
                        i.itemComboboxRowDisabled = true
                    }
                })
            }
            else {
                this.item.forEach(i => {
                    i.itemComboboxRowDisabled = false
                })
            }
        },
        /**
         * After the combobox for the gpc levels get an selected input of a specific gpc level
         * call the function to restrict the chice of the other combobocxes based on which combobox 
         * is slected and which gpc id is selected. Reset all restrictet gpc level, if combobx was cleared
         * @param value 
         * @param item 
         */
        updateGPCLevel(value, item, level) {
            if (value != null) {
                this.$emit('is_multiple_saving_enable', false)
                this.changeDisabledForItemRows(item, true)
                item.gpc = JSON.parse(JSON.stringify(value))
                this.tmpLevel2 = []
                this.tmpLevel3 = []
                this.restrictLowerGPCLevelChoice(item)
                return
            }
            const keys = Object.keys(item.gpc)
            if (level == 1) {
                this.changeDisabledForItemRows(item, false)
                for (const element of keys) {
                    item.gpc[element] = null
                }
                for (let prop in item.gpc) {
                    item.gpc[prop] = null
                }
                this.$emit('is_multiple_saving_enable', true)
            }
            if (level == 2) {
                for (let i = 2; i < keys.length; i++) {
                    item.gpc[keys[i]] = null
                }
            }
            if (level == 3) {
                for (let i = 4; i < keys.length; i++) {
                    item.gpc[keys[i]] = null
                }
            }
            if (level == 4) {
                for (let i = 6; i < keys.length; i++) {
                    item.gpc[keys[i]] = null
                }
            }
            this.gpcLocal = JSON.parse(JSON.stringify(this.tmpGpc))
            this.restrictChoosableGPCLevelWhenLevelIsChoosen(item, item.status, level)
        },
        /**
         * Save the values of the item temporarly while the user is editing the gpc ids of the item
         * @param value
         */
        editItem(value) {
            value.itemEdit = true
            this.iconDisabled = true
            this.tmpItem = JSON.parse(JSON.stringify(value))
            this.tmpLevel2 = []
            this.tmpLevel3 = []
            this.restrictLowerGPCLevelChoice(value)
        },
        /**
         * If the user dont want to save the edited item reverse the edit and laod the temporarly saved item
         * @param value 
         */
        reverseEditItem(value) {
            value.gpc = this.tmpItem.gpc
            value.itemEdit = false
            this.iconDisabled = false
            this.gpcLocal = JSON.parse(JSON.stringify(this.tmpGpc))
        },
        /**
         * Not in use now
         * @param item 
         */
        changeRefundable(item) {
            item.refundable = !item.refundable
        },
        /**
         * Add extra information that are important for the frontend to work with
         * @param item 
         */
        extendItem(item) {
            let extend_item = {
                expand: false,
                itemEdit: false,
                itemComboboxRowDisabled: false,
                isSelected: false,
                status: this.selectedTab,
                refundable: true,
                gpc: {
                    level_1_code: null,
                    level_1_title: null,
                    level_2_code: null,
                    level_2_title: null,
                    level_3_code: null,
                    level_3_title: null,
                    level_4_code: null,
                    level_4_title: null,
                },
            }
            let item_new = { ...item, ...extend_item }
            return item_new
        },
        /**
         * When in the combobox a gpc level is choosen, restrict the choice of the lower comboboxes
         * @param item 
         * @param status 
         * @param level 
         */
        restrictChoosableGPCLevelWhenLevelIsChoosen(item, status, level) {
            if (item.gpc.level_1_code == null) { return }
            if (level == 2) {
                this.restrictLevel2(item)
                this.restrictLevel3(item)
                this.restrictLevel4(item)
                return
            }
            if (level == 3) {
                this.restrictLevel3(item)
                this.restrictLevel4(item)
                return
            }
            if (level == 4) {
                this.restrictLevel4(item)
            }
        },
        /**
        * If a gpc level is choosen in the v-combobox, the gpc levels under the choosen level
        * should only contain all sub levels of the choosen level
        */
        restrictLowerGPCLevelChoice(item) {
            // Level 1 choosen
            if (item.gpc.level_2_code == null) {
                this.restrictLevel2(item)
                this.restrictLevel3(item)
                this.restrictLevel4(item)
            }
            // Level 2 choosen
            if (item.gpc.level_3_code == null) {
                this.restrictLevel3(item)
                this.restrictLevel4(item)
            }
            // Level 3 choosen
            if (item.gpc.level_4_code == null) {
                this.restrictLevel4(item)
            }
        },
        /**
         * Iterate over each gpc object of the gpc level 2 and check if the level 1 code of these 
         * level 2 gpc object is the same as the level 1 code of the choosen level 1 of the 
         * combobox
         * @param item 
         */
        restrictLevel2(item) {
            this.gpcLocal.level_2.forEach(level_2 => {
                if (level_2.level_1_code == item.gpc.level_1_code) {
                    this.tmpLevel2.push(level_2)
                }
            })
            this.gpcLocal.level_2 = this.tmpLevel2
        },
        /**
         * Iterate over each gpc object of the gpc level 3 and check if the level 2 code of these 
         * level 3 gpc object is the same as the level 1 code of the choosen level 2 of the 
         * combobox
         * @param item 
         */
        restrictLevel3(item) {
            this.gpcLocal.level_3.forEach(level_3 => {
                if (this.tmpLevel2.length == 0) {
                    if (level_3.level_2_code == item.gpc.level_2_code) {
                        this.tmpLevel3.push(level_3)
                    }
                }
                else {
                    this.tmpLevel2.forEach(level_2 => {
                        if (level_3.level_2_code == level_2.level_2_code) {
                            this.tmpLevel3.push(level_3)
                        }
                    })
                }
            })
            this.gpcLocal.level_3 = this.tmpLevel3
        },
        /**
         * Iterate over each gpc object of the gpc level 4 and check if the level 3 code of these 
         * level 4 gpc object is the same as the level 3 code of the choosen level 3 of the 
         * combobox
         * @param item 
         */
        restrictLevel4(item) {
            let tmp_level_4 = []
            this.gpcLocal.level_4.forEach(level_4 => {
                if (this.tmpLevel3.length == 0) {
                    if (level_4.level_3_code == item.gpc.level_3_code) {
                        tmp_level_4.push(level_4)
                    }
                }
                else {
                    this.tmpLevel3.forEach(level_3 => {
                        if (level_4.level_3_code == level_3.level_3_code) {
                            tmp_level_4.push(level_4)
                        }
                    })
                }
            });
            this.gpcLocal.level_4 = tmp_level_4
        },
        /**
         * Add lowest gpc id 
         * @param item 
         */
        setLowestGPCId(item) {
            if (item.gpc.level_4_code != null) { item.gpc_code = item.gpc.level_4_code; return }
            if (item.gpc.level_3_code != null) { item.gpc_code = item.gpc.level_3_code; return }
            if (item.gpc.level_2_code != null) { item.gpc_code = item.gpc.level_2_code; return }
            if (item.gpc.level_1_code != null) { item.gpc_code = item.gpc.level_1_code; return }
            item.gpc_code = null
        },
        /**
         * Add all gpc level from the lowest and above to show it for the "Zugeordnet" items in the data table
         * @param item 
         */
        addGPCLevelFromLowestGPC(item) {
            this.gpc.level_1.forEach(level_1 => { if (level_1.level_1_code == item.gpc_code) { item.gpc = { ...item.gpc, ...level_1 } } })
            this.gpc.level_2.forEach(level_2 => { if (level_2.level_2_code == item.gpc_code) { item.gpc = { ...item.gpc, ...level_2 } } })
            this.gpc.level_3.forEach(level_3 => { if (level_3.level_3_code == item.gpc_code) { item.gpc = { ...item.gpc, ...level_3 } } })
            this.gpc.level_4.forEach(level_4 => { if (level_4.level_4_code == item.gpc_code) { item.gpc = { ...item.gpc, ...level_4 } } })
            return item
        },
        /**
         * Set the value for disable/enable of the comboboxes
         * @param index 
         */
        isDisabled(index) {
            return this.anySelection && !this.item[index].comboboxes.some(combobox => combobox.selected !== null)
        },
        /**
         * Delete the selected items of the data table
         */
        deleteSelectedItemsOfDatatable(){
            this.selectedItemsForDeleting.forEach(item => {
                this.item = this.item.filter(item_of_list => item_of_list.item_id !== item.item_id)
            })
            this.selectedItems = []
        },
        /**
         * Change extend row flag
         * @param item 
         */
        changeExpand(item) {
            item.expand = !item.expand
        },
        /**
         * Create a alert pop up message
         * @param message 
         * @param type 
        */
        newAlert(message, type) {
            this.alertMessage = message;
            this.alertType = type;
            this.$refs.alert.newAlert();
        }
    },
    async mounted() {
        await this.getItems().then(() => {
            // Copy the gpc levels before they wil be restricted by the combobox after user choose a gpc level
            // in a combobox. The save is here, because the getItems() will be triggered after the ArtikelAppView 
            // processed the response from the gpc level get call
            this.tmpGpc = JSON.parse(JSON.stringify(this.gpc))
        })
        this.loading = false
    },
    computed: {
        anySelection() {
            return this.item.some(i => i.isSelected)
        }
    },
    watch: {
        searchCreditor: function () {
            if (this.searchCreditor != null && (this.searchDescription == '' || this.searchDescription == null) ) {
                this.getItemsByCreditorId()
                return
            }
            if (this.searchCreditor != null && (this.searchDescription != '' || this.searchDescription != null)) {
                this.getItemsByCreditorIdAndItemDescription()
                return
            }
            this.getItems()
        },
        searchDescription: function () {
            if (this.searchDescription != null && (this.searchCreditor == '' || this.searchCreditor == null)) {
                this.getItemsByDescription()
                return
            }
            if (this.searchDescription != null && (this.searchCreditor != '' || this.searchCreditor != null)) {
                this.getItemsByCreditorIdAndItemDescription()
                return
            }
            this.getItems()
        },
        searchId: function () {
            if (this.searchId != null) {
                this.getItemById()
                return
            }
            this.getItems()
        },
        searchIds: function () {
            if (this.searchIds != null) {
                this.getItemByNo()
                return
            }
            this.getItems()
        },
        searchGPCIds: function () {
            if (this.searchGPCIds.length != 0) {
                this.getItemsByGPCIds()
                return
            }
            this.getItems()
        },
        selectedTab: function () {
            this.getItemsAfterTabChange()
        },
        selectedItemsForDeleting: function () {
            this.deleteSelectedItemsOfDatatable()
        },
        'tableOptions.sortBy'(newSortBy) {
            this.tableOptions.sortBy = newSortBy;
        },
    }
}
</script>
<style scoped>
    ::v-deep .combobox-fontsize .v-label {
        font-size: 11px;
    }
    ::v-deep .combobox-height {
        height: auto;
        padding: 0px;
        margin: 0px;
        font-size: 11px;
    }
    ::v-deep .v-list-item {
        padding: 0px;
        padding-left: 16px;
        padding-right: 16px;
        min-height: 0px;
    }
    ::v-deep .v-list-item__content {
        font-size: 11px;
        min-height: 0px;
        padding: 6px;
    }
</style>