import axios from 'axios';

const RetryCount = 2;
const RetryWaitMs = 100;

export default {
    created() {
        this.api = this.createApi();
        this.artikelAppApi = this.createArtikelAppApi()
    },
    data: () => ({
        api: null,
        artikelAppApi: null
    }),
    methods: {

        createArtikelAppApi() {
            const me = this;
            let api = axios.create({
                baseURL: process.env.VUE_APP_ARTIKEL_APP_URL,
                timeout: 100000000,
                retry: RetryCount,
                retryDelay: RetryWaitMs
            })
            api.interceptors.request.use((config) => me.callArtikelAppInterceptor(config));
            api.interceptors.response.use(
                (response) => response,
                (error) => me.errorInterceptor(error));

            return api;
        },
        createApi() {
            const me = this;
            let api = axios.create({
                baseURL: process.env.VUE_APP_BASE_URL,
                timeout: 100000000,
                retry: RetryCount,
                retryDelay: RetryWaitMs
            })
            api.interceptors.request.use((config) => me.callInterceptor(config));
            api.interceptors.response.use(
                (response) => response,
                (error) => me.errorInterceptor(error));
            return api;
        },

        callInterceptor(config) {
            const sessionInfo = localStorage.getObject('sessionInfo');
            if (!sessionInfo) return config;
            if (!sessionInfo.KeyCloakParam) return config;
            if (!sessionInfo.KeyCloakParam.KeyCloakToken) return config;
            config.headers = {
                'x-functions-key': sessionInfo.FuncAppKey,
                common: {        // can be common or any other method
                    accessToken: sessionInfo.KeyCloakParam.KeyCloakToken.access_token,
                }
            }
            return config;
        },

        callArtikelAppInterceptor(config){
            const sessionInfo = localStorage.getObject('sessionInfo');
            if (!sessionInfo) return config;
            if (!sessionInfo.KeyCloakParam) return config;
            if (!sessionInfo.KeyCloakParam.KeyCloakToken) return config;
            config.headers = {
                'x-functions-key': sessionInfo.FuncArtikelAppKey,
                common: {        // can be common or any other method
                    accessToken: sessionInfo.KeyCloakParam.KeyCloakToken.access_token,
                }
            }
            return config;
        },

        async errorInterceptor(error) {
            console.log('axios.interceptors error: ' + error + (error.status != undefined ? '\nStatus: ' + error.status.data : ''));
            if (error.message && error.message == "Network Error") {
                this.showError("Die Server-Schnittstelle ist nicht verfügbar. Bitte versuchen Sie es später erneut.");
                return;
            }
            const { config } = error;
            if (!config || !config.retry) {
                if (error.response && error.response.status && error.response.status == 403) {
                    this.$router.go(0);
                    return Promise.reject(error);
                }
                return this.$confirm({
                    title: "Unbekannter Fehler",
                    text: "Es ist ein unbekannter Fehler aufgetreten. Bitte melden Sie sich beim Support.\n" + error,
                    dialogMaxWidth: 400,
                    acceptText: "Neu laden",
                    cancelText: "Schliessen"
                }).then(ifaccept => {
                    if (ifaccept) this.$router.go(0);
                }).catch(error => {
                    console.log(error);
                });
            }
            if (error.response && error.response.status) {
                console.log('returned status: ' + error.response.status);
                if (error.response.status == 401 || error.response.status == 403) {
                    return this.refreshSessionOrLogin().then(() => {
                        config.retry--;
                        console.log("token refreshed by retry: " + (RetryCount - config.retry) + "/" + RetryCount);

                        const apiInstance = config.baseURL.includes(process.env.VUE_APP_ARTIKEL_APP_URL) 
                            ? this.artikelAppApi 
                            : this.api;
                        return this.retryRequest(config, RetryCount - config.retry, config.retryDelay || RetryWaitMs, apiInstance);
                    });
                } else if (error.response.status == 404) {
                    return this.$confirm({
                        title: "Server nicht erreichbar",
                        text: "Der Server '" + process.env.VUE_APP_BASE_URL + "' konnte nicht erreicht werden. Bitte laden sie die Seite neu.\n" + error,
                        dialogMaxWidth: 400,
                        acceptText: "Neu laden",
                        cancelText: "Schliessen"
                    }).then(ifaccept => {
                        if (ifaccept) this.$router.go(0);
                    }).catch(error => {
                        console.log(error);
                    });
                } else {
                    this.showError(error);
                }
            }
            return Promise.reject(error);
        },


        retryRequest(config, retryCount, retryDelay, apiInstance) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    console.log(`Retrying request: ${config.url}, Attempt: ${retryCount}`);
                    resolve(apiInstance(config));
                }, retryDelay);
            });
        },
        

        showError(error) {
            console.log("showError");
            console.log(error.response);
            this.$alert({
                title: "Es ist ein Fehler aufgetreten",
                text: error,
                dialogMaxWidth: 400,
                acceptText: "Schliessen"
            });
        },

        async refreshSessionOrLogin() {
            const me = this;
            const sessionInfo = localStorage.getObject("sessionInfo");
            let refreshToken = null;
            let res = null;
            if (sessionInfo) {
                refreshToken = sessionInfo.KeyCloakParam.KeyCloakToken.refresh_token;
            }
            try {
                res = await me.api.get(`PBiAppAuthFlow?refresh=${refreshToken}`, {
                    retry: null // Null because it will then call itself!
                });
            }
            catch (err) {
                localStorage.setObject("sessionInfo", null);
                window.location.href = process.env.VUE_APP_LOGOUT_URL;
            }
            if (res && res.status === 200) {
                localStorage.setObject("sessionInfo", res.data);
                localStorage.removeItem("echtId");
            }
            else {
                localStorage.setObject("sessionInfo", null);
                window.location.href = process.env.VUE_APP_LOGOUT_URL;
            }
        }
    }
};
