/**
 *
 * @class
 * @classdesc
 */
export default class UserService {
  constructor(sessionStorage = localStorage) {
    this.sessionStorage = sessionStorage; // Dependency Injection für Session-Daten
  }

  /**
   * Check if the user has the role
   * @param {string} role - Role to check
   * @returns {boolean} Returns true if the user has the role
   */
  checkUserRole(role) {
    const sessionInfo = this.sessionStorage.getObject("sessionInfo");
    const userRoles = sessionInfo?.KeyCloakParam?.KeyCloakUserRoles || [];
    return userRoles.some((userRole) => userRole.Role === role);
  }
}
