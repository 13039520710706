<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="echtPartner"
      item-key="name"
      fixed-header
      :height="calculatedHeight"
      hide-default-footer
      disable-pagination
      dense
      :loading="loading"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <input
              type="checkbox"
              v-model="selected"
              :value="item"
              :hidden="selectable ? false : true"
              :disabled="item.Dirty == 0 ? true : false"
            />
          </td>
          <td>
            <v-icon
              v-if="item.ECHT_PARTNER_ID == item.ECHT_PARTNER_ID_PARENT"
              color="orange"
              dark
              >mdi-crown</v-icon
            >
          </td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  dark
                  class="ma-1 text-h6"
                  v-bind="attrs"
                  v-on="on"
                  @click="copyToClipboard(item.SOURCE_UNIQUE_ID, 'source_id')"
                >
                  {{ splitSourceId(item.SOURCE_UNIQUE_ID, 0) }}
                </span>
              </template>
              <span>
                {{ splitSourceId(item.SOURCE_UNIQUE_ID, 1) }}
              </span>
            </v-tooltip>
          </td>
          <td>{{ item.short_name }}</td>
          <td>
            <v-icon
              v-if="item.ACCOUNT_ID"
              color="primary"
              dark
              @click="redirectToCRM(item.ACCOUNT_ID)"
            >
              mdi-microsoft-dynamics-365</v-icon
            >
            <v-icon v-else color="grey" dark>mdi-microsoft-dynamics-365</v-icon>
          </td>
          <td>{{ item.ECHT_PARTNER_ID }}</td>
          <td>
            <div :class="styles['chip-container']">
              <v-chip
                v-if="item.name_1"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.name_1 }}
              </v-chip>
              <v-chip
                v-if="item.name_2"
                outlined
                small
                :class="styles['apiPaentered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.name_2 }}
              </v-chip>
              <v-chip
                v-if="item.name_3"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.name_3 }}
              </v-chip>
            </div>
          </td>
          <td>
            <a> {{ item.country }}, {{ item.zip_city }}, {{ item.street }} </a>
          </td>
          <td>
            <div :class="styles['chip-container']">
              <v-chip
                v-if="item.homepage"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.homepage }}
              </v-chip>
              <v-chip
                v-if="item.mail"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.mail }}
              </v-chip>
              <v-chip
                v-if="item.phone"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.phone }}
              </v-chip>
            </div>
          </td>
          <td>
            <div :class="styles['chip-container']">
              <v-chip
                v-if="item.iln"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.iln }}
              </v-chip>
              <v-chip
                v-if="item.tax_id"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.tax_id }}
              </v-chip>
              <v-chip
                v-if="item.vat_id"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.vat_id }}
              </v-chip>
            </div>
          </td>
          <td>
            <div :class="styles['chip-container']">
              <v-chip
                v-if="item.iban"
                outlined
                small
                :class="styles['centered-text']"
                class="ma-1"
                color="primary"
                dark
              >
                {{ item.iban }}
              </v-chip>
            </div>
          </td>
          <td>
            <v-icon v-if="item.Dirty == 0" color="green" dark>mdi-flag</v-icon>
            <v-icon v-else color="error" dark>mdi-flag</v-icon>
          </td>
          <td>
            <SplitPartnerDialog
              :item="item"
              @splitPartner="handleSplitPartner"
              :hidden="splitable ? false : true"
              :parentPartnerId="parentPartnerId"
            ></SplitPartnerDialog>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import SplitPartnerDialog from "../../../dialogs/partnerApp/SplitPartnerDialog.vue";

import apiPartnerService from "@/services/api/apiPartnerService";

import styles from "@/styles/internal/PartnerAppView.module.scss";

const emitter = require("tiny-emitter/instance");

export default {
  props: {
    selectable: {
      type: Boolean,
      default: true,
      required: true,
    },
    splitable: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      search: "",
      parentPartnerId: null,
      echtPartner: [],
      headers: [
        { text: "", value: "" },
        { text: "", value: "mainPartner" },
        { text: "", value: "source" },
        { text: "Kurzname", value: "name" },
        { text: "CRM", value: "crm" },
        { text: "EchtId", value: "echtPartnerId" },
        { text: "Namen", value: "names" },
        { text: "Anschrift", value: "address" },
        { text: "Kontakt", value: "contact" },
        { text: "Steuer", value: "steuer" },
        { text: "Bank", value: "bank" },
        { text: "Bestätigt", value: "actions" },
        { text: "", value: "actions", sortable: false },
      ],
      loading: false,
      styles,
    };
  },
  components: {
    SplitPartnerDialog,
  },
  computed: {
    calculatedHeight() {
      return "calc((100vh - 300px) / 2)";
    },
  },
  watch: {
    selected(newSelected) {
      this.$emit("confirmedChilds", newSelected);
    },
  },
  methods: {
    copyToClipboard(value, type) {
      if (type === "source_id") {
        navigator.clipboard.writeText(value.substring(2));
      } else {
        navigator.clipboard.writeText(value);
      }
    },
    splitSourceId(item, element) {
      switch (element) {
        case 0:
          return item.charAt(0);
        case 1:
          return item.substring(2);
        default:
          return item;
      }
    },
    async handleSplitPartner() {
      await this.refreshChilds();
      emitter.emit("refreshParent");
    },
    async refreshChilds() {
      this.$log.debug("refreshChilds");
      this.loading = true;
      this.selected = [];
      this.echtPartner = await apiPartnerService.getEchtPartnerChildsById(
        this.parentPartnerId
      );
      this.loading = false;
    },
    redirectToCRM(ACCOUNT_ID) {
      //TODO: put in env file
      //window.location.href = `https://echt.crm4.dynamics.com/main.aspx?appid=81ce95b5-b5cd-4c71-9783-bf65f4a8380c&forceUCI=1&pagetype=entityrecord&etn=account&id=${ACCOUNT_ID}`;
      //open in new tab
      window.open(
        `https://echt.crm4.dynamics.com/main.aspx?appid=81ce95b5-b5cd-4c71-9783-bf65f4a8380c&forceUCI=1&pagetype=entityrecord&etn=account&id=${ACCOUNT_ID}`,
        "_blank"
      );
    },
  },
  mounted() {
    emitter.on("echtPartnerId", async (echtPartnerId) => {
      this.echtPartner = [];
      this.loading = true;
      this.parentPartnerId = echtPartnerId;
      this.echtPartner = await apiPartnerService.getEchtPartnerChildsById(
        echtPartnerId
      );
      this.$log.debug("echtPartner childs", this.echtPartner);
      this.loading = false;
    });
  },
};
</script>
