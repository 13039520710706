<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="echtPartnerWithSelection"
      item-key="name"
      fixed-header
      :height="calculatedMaxHeight"
      dense
      :search="search"
      :loading="loading"
      :sort-by="['NewCount']"
      :sort-desc="[true]"
    >
      <template v-slot:item="{ item }">
        <tr @click="handleClick(item)" :class="{ selected: item.isSelected }">
          <td>{{ item.ParentID }}</td>
          <td>{{ item.short_name }}</td>
          <td>{{ item.NewCount }}</td>
          <td>{{ item.Children }}</td>
          <td v-if="editable">
            <v-btn icon @click="editParentPartner(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
const emitter = require("tiny-emitter/instance");

export default {
  data() {
    return {
      echtPartner: [],
      headers: [
        {
          text: "Echt Partner ID",
          align: "start",
          sortable: true,
          value: "ParentID",
        },
        { text: "Lieferantenname", value: "short_name" },
        { text: "Neu", value: "NewCount" },
        { text: "Gesamt", value: "Children" },
        { text: "", value: "actions", sortable: false },
      ],
      api: axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        timeout: 100000000,
      }),
      selectedItemId: null,
      loading: false,
    };
  },
  computed: {
    echtPartnerWithSelection() {
      return this.echtPartner.map((item) => ({
        ...item,
        isSelected: item.ParentID === this.selectedItemId,
      }));
    },
    calculatedMaxHeight() {
      return this.maxHeigth;
    },
  },
  methods: {
    async getPartner() {
      this.loading = true;
      await this.api.get("GetEchtPartnerParents").then((response) => {
        this.echtPartner = response.data;
        this.loading = false;
      });
    },
    handleClick(item) {
      this.selectedItemId = item.ParentID;
      this.$emit("selectParent", item);
      emitter.emit("echtPartnerId", item.ParentID);
    },
    editParentPartner(item) {
      emitter.emit("editParentPartner", item.ParentID);
    },
    refresh() {
      this.getPartner();
    },
  },
  mounted() {
    this.getPartner();
    emitter.on("partnerMerged", () => {
      this.$emit("selectParent", "");
      this.echtPartner = [];

      this.getPartner();
    });
    emitter.on("refreshParent", () => {
      this.refresh();
    });
  },
  props: {
    search: {
      type: String,
      default: "",
    },
    maxHeigth: {
      type: String,
      default: "calc((100vh - 300px) / 2",
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.selected {
  background-color: #eeeeee;
}
</style>
