<template>
    <v-container fluid v-if="isLoggedIn == true" class="full-height" width="100%" style="padding-bottom: 0px;">
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <h5>
                    Artikel App
                </h5>
            </v-col>
        </v-row>
        <div style="padding: 0px;">
            <v-row>
                <v-col cols="12" sm="12" md="12" style="padding: 0px; font-size: 11px;">
                    <v-tabs v-model="tab">
                        <v-tab @click="changeTabs(0)">
                            Nicht zugeordnet
                        </v-tab>
                        <v-tab @click="changeTabs(1)">
                            Ungerpüfte Zuordnung
                        </v-tab>
                        <v-tab @click="changeTabs(2)">
                            Gerpüfte Zuordnung
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>
        </div>
        <v-row>
            <div style="width: 100%">
                <v-col cols="12" sm="12" md="12">
                    <FilterSearch 
                        :editable="true" 
                        :selectedTab="selectedTab" 
                        :isAuthComplete="isAuthComplete"
                        @searchDescription="this.passSearchDescription"
                        @searchId="this.passSearchId"
                        @searchIds="this.passSearchIds" 
                        @searchCreditor="this.passSearchCreditor"
                        @searchGPCIds="this.passSearchGPCIds">
                    </FilterSearch>
                </v-col>
            </div>
        </v-row>
        <v-row>
            <div style="width: 100%">
                <v-col cols="12" sm="12" md="12">
                    <ItemInformation 
                        v-if="gpcIsLoaded"
                        :gpc="gpc" 
                        :selectedTab="selectedTab" 
                        :searchDescription="searchDescription"
                        :searchId="searchId"
                        :searchIds="searchIds" 
                        :searchCreditor="searchCreditor" 
                        :searchGPCIds="searchGPCIds"
                        :selectedItemsForDeleting="selectedItemsForDeleting"
                        @save_items="this.setSaveItemsToArray"
                        @save_edit_item="this.saveItem"
                        @save_check_item="this.saveCheckItem"
                        @save_edited_check_item="this.saveItem"
                        @save_first_classification_item="this.saveItem" 
                        @is_multiple_saving_enable="this.changeSaveButtonStatus">
                    </ItemInformation>
                </v-col>
            </div>
        </v-row>
        <v-row height="calc((100vh - 500px) / 20" style="width: 100%;" align-self="bottom " :key="renderKey">
            <v-col cols="12" sm="2" md="2">
                <v-combobox 
                    v-model="gpcModel"
                    item-text="level_1_title" 
                    item-value="level_1_code" 
                    label="GPC-Level-1"
                    clearable 
                    hide-details="auto"
                    @input="updateGPCLevel($event, 1)"
                    :items="gpc.level_1"></v-combobox>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-combobox 
                    v-model="gpcModel" 
                    item-text="level_2_title" 
                    item-value="level_2_code" 
                    label="GPC-Level-2"
                    clearable 
                    hide-details="auto"
                    @input="updateGPCLevel($event, 2)" 
                    :items="gpc.level_2" ></v-combobox>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-combobox
                    v-model="gpcModel"  
                    item-text="level_3_title" 
                    item-value="level_3_code" 
                    label="GPC-Level-3"
                    clearable 
                    hide-details="auto"
                    @input="updateGPCLevel($event, 3)" 
                    :items="gpc.level_3"></v-combobox>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-combobox 
                    v-model="gpcModel" 
                    item-text="level_4_title" 
                    item-value="level_4_code" 
                    label="GPC-Level-4"
                    clearable 
                    hide-details="auto"
                    @input="updateGPCLevel($event, 4)" 
                    :items="gpc.level_4"></v-combobox>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <div v-if="tab == 0">
                    <v-btn class="ma-2" color="primary" @click="saveMultipleFirstClassificationItems" :disabled="isMultipleSavingEnable == false">
                        Massenspeichern
                    </v-btn>
                </div>
                <div v-else>
                    <v-btn class="ma-2" color="primary" @click="saveMultipleEditItems" :disbaled="isMultipleSavingEnable == false">
                        Massenüberarbeiten
                    </v-btn>
                </div>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                    <v-btn class="ma-2" color="primary" @click="checkMultipleItems" :disabled="tab==0 || tab == 2">
                        Massenbestätigen
                    </v-btn>
            </v-col>
        </v-row>
        <PopupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </PopupAlert>
    </v-container>
</template>


<style scoped>
    .full-height {
        height: calc(100vh - 80px);
    }
    ::v-deep .v-list-item {
        padding: 0px;
        padding-left: 16px;
        padding-right: 16px;
        min-height: 0px;
    }
    ::v-deep .v-list-item__content {
        font-size: 11px;
        min-height: 0px;
        padding: 6px;
    }
</style>

<script>

import FilterSearch from "@/components/artikelApp/filterSearch.vue";
import ItemInformation from "@/components/artikelApp/itemInformation.vue";
import apiMixin from '@/mixins/apiMixin.js';

import PopupAlert from "@/components/popupAlert.vue";

export default
    {
        data() {
            return {
                alertMessage: '',
                alertType: 'error',
                gpcIsLoaded: false,
                gpcModel: null,
                gpcTransform: [],
                isAuthComplete: false,
                isMultipleSavingEnable: true,
                loading: false,
                selectedItems: [],
                selectedItemsForDeleting: [],
                searchDescription: '',
                searchCreditor: '',
                searchId: '',
                searchIds: [],
                searchGPCIds: [],
                selectedTab: 0,
                tab: 0,
                tmpGpc: null,
                tmpGpcModel: null,
                tmpLevel2: [],
                tmpLevel3: [],
                renderKey: 0,
                gpc: {
                    level_1: [],
                    level_2: [],
                    level_3: [],
                    level_4: [],
                },
                gpcForFilterSearch: {}
            }
        },
        name: "artikel-app-view",

        mixins: [apiMixin],

        props: {
            isLoggedIn: Boolean,
        },

        components: {
            FilterSearch,
            ItemInformation,
            PopupAlert
        },

        methods: {
            /**
             * After user pressed the button "Massenüberarbeiten" the edited gpc levels will be saved to the database
             */
            async saveMultipleEditItems() {
                if (this.selectedItems.length == 0) { this.newAlert(`Bitte Artikel auswählen`, 'info'); return }
                if (this.gpcModel == null) { this.newAlert(`Bitte ein GPC-Level auswählen`, 'info'); return }
                if(this.gpcModel.level_4_code == null){
                    this.newAlert(`Bitte wählen Sie alle GPC-Level aus`, 'error');
                    return
                }
            
                this.selectedItems.forEach(selected_item => {
                    this.saveItemFromMultiple(selected_item)
                })
                this.gpcModel = null
                this.disableAllCheckBoxes()
                this.selectedItems = []
            },
            /**
             * After user pressed the button "Massenspeichern" the lowest gpc id will be 
             * determined and assign to each item before saving it to the database
             */
            async saveMultipleItems() {
                if (this.selectedItems.length == 0) { this.newAlert(`Bitte Artikel auswählen`, 'info'); return }
                if (this.gpcModel == null) { this.newAlert(`Bitte ein GPC-Level auswählen`, 'info'); return }
                if(this.gpcModel.level_4_code == null){
                    this.newAlert(`Bitte wählen Sie alle GPC-Level aus`, 'error');
                    return
                }
                this.selectedItemsForDeleting = []
                this.selectedItems.forEach(selected_item => {
                    this.saveItemFromMultiple(selected_item)
                })
                this.selectedItemsForDeleting = JSON.parse(JSON.stringify(this.selectedItems))
                this.gpcModel = null
                this.selectedItems = []
            },
            /**
             * Save item with lowest gpc id to database
             * @param item 
             */
            async saveItemFromMultiple(item) {
                const timeStamp = new Date().getTime()
                const newTitle = this.gpcModel.level_1_title + ' | ' + this.gpcModel.level_2_title + ' | ' + this.gpcModel.level_3_title + ' | ' + this.gpcModel.level_4_title
                try {
                    await this.artikelAppApi.patch('updateGPCLevel?item_id=' + item.item_id + '&gpc_code=' + this.gpcModel.level_4_code + '&title=' + newTitle + '&manually_set_timestamp=' + timeStamp).then(
                            this.newAlert(`Ausgewählter Artikel ist jetzt bestätigt`, 'success')
                        );
                } catch (error) {
                    console.log(error)
                    this.newAlert(`Ausgewählter Artikel kann nicht bestätigt werden`, 'error')
                }
            },

            /**
             * Save item with lowest gpc id to database (setGPCLevel call, because to save 99999999 as old gpc_code) 
             * @param item 
             */
             async saveItemFromMultipleFirstClassification(item) {
                const timeStamp = new Date().getTime()
                const newTitle = this.gpcModel.level_1_title + ' | ' + this.gpcModel.level_2_title + ' | ' + this.gpcModel.level_3_title + ' | ' + this.gpcModel.level_4_title
                try {
                    await this.artikelAppApi.patch('setGPCLevel?item_id=' + item.item_id + '&gpc_code=' + this.gpcModel.level_4_code + '&title=' + newTitle + '&manually_set_timestamp=' + timeStamp).then(
                            this.newAlert(`Ausgewählter Artikel ist jetzt bestätigt`, 'success')
                        );
                } catch (error) {
                    console.log(error)
                    this.newAlert(`Ausgewählter Artikel kann nicht bestätigt werden`, 'error')
                }
            },

            /**
             * 
             * @param item 
             */
            async saveItem(item) {
                if(item.gpc.level_4_code == null){
                    this.newAlert(`Bitte wählen Sie alle GPC-Level aus`, 'error');
                    return
                }
                const timeStamp = new Date().getTime()
                const newTitle = item.gpc.level_1_title + ' | ' + item.gpc.level_2_title + ' | ' + item.gpc.level_3_title + ' | ' + item.gpc.level_4_title
                try {
                    await this.artikelAppApi.patch('setGPCLevel?item_id=' + item.item_id + '&gpc_code=' + item.gpc.level_4_code + '&title=' + newTitle + '&manually_set_timestamp=' + timeStamp).then(
                            this.newAlert(`Ausgewählter Artikel wurde als geprüft bestätigt`, 'success')
                        );
                } catch (error) {
                    console.log(error)
                    this.newAlert(`Ausgewählter Artikel kann nicht als geprüft bestätigt werden`, 'error')
                }
                this.isMultipleSavingEnable = true
                this.disableAllCheckBoxes()
            },

            /**
             * 
             * @param item 
             */
            async saveMultipleFirstClassificationItems() {
                if(this.gpcModel.level_4_code == null){
                    this.newAlert(`Bitte wählen Sie alle GPC-Level aus`, 'error');
                    return
                }
                this.selectedItemsForDeleting = []
                this.selectedItems.forEach(selected_item => {
                    this.saveItemFromMultipleFirstClassification(selected_item)
                })
                this.selectedItemsForDeleting = JSON.parse(JSON.stringify(this.selectedItems))
                this.gpcModel = null
                this.isMultipleSavingEnable = true
                this.selectedItems = []
            },

            /**
             * Disable all checkboxes after user has pressed the buttons Massenspeichern or Massenbestätigen or Massenüberarbeiten or Bestätigen or Überarbeiten
             */
            disableAllCheckBoxes() {
                this.selectedItems.forEach(item => {
                    item.isSelected = false
                })
            },

            /**
             * Set selected items from the data table form the ItemInformation View to selectedItems array to save it
             * after user pressed the button "Massenspeichern"
             * @param item 
             */
            setSaveItemsToArray(item) {
                this.selectedItems = item
            },
            
            /**
             * Save all choosen Items at once like thier are check all each one by one
             */
            checkMultipleItems() {
                if (this.selectedItems.length == 0) { this.newAlert(`Bitte Artikel zum bestätigen auswählen`, 'error'); return }
                this.selectedItemsForDeleting = []
                this.selectedItems.forEach(selected_item => {
                    this.saveCheckItem(selected_item)
                })
                this.selectedItemsForDeleting = JSON.parse(JSON.stringify(this.selectedItems))
            },

            /**
             * Save timestamp item whihc is checked by the user
             * @param item 
             */
            async saveCheckItem(item) {
                const timeStamp = new Date().getTime()
                try {
                    await this.artikelAppApi.patch('saveGPCLevel?item_id=' + item.item_id + '&manually_set_timestamp=' + timeStamp).then(
                        this.newAlert(`Ausgewählter Artikel ist jetzt bestätigt`, 'success')
                    );
                } catch (error) {
                    console.log(error)
                    this.newAlert(`Ausgewählter Artikel kann nicht bestätigt werden`, 'error')
                }
                this.disableAllCheckBoxes()
            },

            /**
             * Change tabs from "Zugeordnet" to "Nicht zugeordnet" and back
             * @param tab 
             */
            changeTabs(tab) {
                if (tab == 0) {
                    this.selectedTab = 0
                }
                else if (tab == 1) {
                    this.selectedTab = 1
                }
                else {
                    this.selectedTab = 2
                }
            },
            
            /**
             * 
             * @param isMultipleSavingEnable 
             */
            changeSaveButtonStatus(isMultipleSavingEnable){
                this.isMultipleSavingEnable = isMultipleSavingEnable
            },
            /**
             * Save item description from the combobox from the FilterSearch View to pass it to the ItemInformation View
             * @param searchDescription 
             */
            passSearchDescription(searchDescription) {
                this.searchDescription = searchDescription
            },

            passSearchId(searchId) {
                this.searchId = searchId
            },

            /**
             * Save item id from the combobox from the FilterSearch View to pass it to the ItemInformation View
             * @param searchIds
             */
            passSearchIds(searchIds) {
                this.searchIds = searchIds
            },
            /**
             * Save creditor from the combobox from the FilterSearch View to pass it to the ItemInformation View
             * @param searchCreditor 
             */
            passSearchCreditor(searchCreditor) {
                this.searchCreditor = searchCreditor
            },
            /**
             * Save gpc ids from the treeview from the FilterSearch View to pass it to the ItemInformation View
             * @param searchGPCIds 
             */
            passSearchGPCIds(searchGPCIds) {
                this.searchGPCIds = searchGPCIds
            },
            /**
             * After the combobox for the gpc levels get an selected input of a specific gpc level
             * call the function to restrict the choice of the other combobocxes based on which combobox 
             * is slected and which gpc id is selected. Reset all restrictet gpc level, if combobx was cleared
             * @param value 
             */
            updateGPCLevel(value, level) {
                if (value == null) {
                    const keys = Object.keys(this.tmpGpcModel);
                    this.gpc = JSON.parse(JSON.stringify(this.tmpGpc))
                    if (level == 1) {
                        for (const element of keys) {
                            this.tmpGpcModel[element] = null
                        }
                        this.gpcModel = JSON.parse(JSON.stringify(this.tmpGpcModel))
                    }
                    if (level == 2 ) {
                        for (let i = 2; i < keys.length; i++) {
                            this.tmpGpcModel[keys[i]] = null
                        }
                        this.gpcModel = JSON.parse(JSON.stringify(this.tmpGpcModel))
                        this.restrictChoosableGPCLevelWhenLevelIsChoosen(1)
                    }
                    if (level == 3) {
                        for (let i = 4; i < keys.length; i++) {
                            this.tmpGpcModel[keys[i]] = null
                        }
                        this.gpcModel = JSON.parse(JSON.stringify(this.tmpGpcModel))
                        this.restrictChoosableGPCLevelWhenLevelIsChoosen(2)
                    }
                    if (level == 4) {
                        for (let i = 6; i < keys.length; i++) {
                            this.tmpGpcModel[keys[i]] = null
                        }
                        this.gpcModel = JSON.parse(JSON.stringify(this.tmpGpcModel))
                        this.restrictChoosableGPCLevelWhenLevelIsChoosen(3)
                    }
                    return
                }
                this.tmpGpcModel = JSON.parse(JSON.stringify(this.gpcModel))
                this.restrictChoosableGPCLevelWhenLevelIsChoosen(level)
            },
            /**
             * When in the combobox a gpc level is choosen, restrict the choice of the lower comboboxes
             * @param level 
             */
            restrictChoosableGPCLevelWhenLevelIsChoosen(level) {
                this.tmpLevel2 = []
                this.tmpLevel3 = []
                if (level == 1) {
                    this.restrictLevel2()
                    this.restrictLevel3()
                    this.restrictLevel4()
                    return
                }
                if (level == 2) {
                    this.restrictLevel3()
                    this.restrictLevel4()
                    return
                }
                if (level == 3) {
                    this.restrictLevel4()
                }
            },
            /**
             * Iterate over each gpc object of the gpc level 2 and check if the level 1 code of these 
             * level 2 gpc object is the same as the level 1 code of the choosen level 1 of the 
             * combobox
             */
            restrictLevel2() {
                this.gpc.level_2.forEach(level_2 => {
                    if (level_2.level_1_code == this.gpcModel.level_1_code) {
                        this.tmpLevel2.push(level_2)
                    }
                })
                this.gpc.level_2 = this.tmpLevel2
            },
            /**
             * Iterate over each gpc object of the gpc level 3 and check if the level 2 code of these 
             * level 3 gpc object is the same as the level 1 code of the choosen level 2 of the 
             * combobox
             */
            restrictLevel3() {
                this.gpc.level_3.forEach(level_3 => {
                    if (this.tmpLevel2.length == 0) {
                        if (level_3.level_2_code == this.gpcModel.level_2_code) {
                            this.tmpLevel3.push(level_3)
                        }
                    }
                    else {
                        this.tmpLevel2.forEach(level_2 => {
                            if (level_3.level_2_code == level_2.level_2_code) {
                                this.tmpLevel3.push(level_3)
                            }
                        })
                    }
                })
                this.gpc.level_3 = this.tmpLevel3
            },
            /**
             * Iterate over each gpc object of the gpc level 4 and check if the level 3 code of these 
             * level 4 gpc object is the same as the level 3 code of the choosen level 3 of the 
             * combobox
             */
            restrictLevel4() {
                let tmpLevel4 = []
                this.gpc.level_4.forEach(level_4 => {
                    if (this.tmpLevel3.length == 0) {
                        if (level_4.level_3_code == this.gpcModel.level_3_code) {
                            tmpLevel4.push(level_4)
                        }
                    }
                    else {
                        this.tmpLevel3.forEach(level_3 => {
                            if (level_4.level_3_code == level_3.level_3_code) {
                                tmpLevel4.push(level_4)
                            }
                        })
                    }
                });
                this.gpc.level_4 = tmpLevel4
            },
            /**
             * Add lowest gpc id after user has pressed the button "Massenspeichern"
             */
            getLowestGCPIdForMultipleItems() {
                if (this.gpcModel == null) { this.newAlert(`Bitte ein GPC-Level auswählen`, 'info'); return }
                if (this.gpcModel.level_4_code != null) { return this.gpcModel.level_4_code }
                if (this.gpcModel.level_3_code != null) { return this.gpcModel.level_3_code }
                if (this.gpcModel.level_2_code != null) { return this.gpcModel.level_2_code }
                if (this.gpcModel.level_1_code != null) { return this.gpcModel.level_1_code }
            },
            /**
             * Create an object where are gpc level 1 to gpc level 4 are saved. For each gpc level 
             * is a object with name level_n inside the gpc object with hold all corresponding ids for this level
             */
            async getGPCLevels() {
                try {
                    this.loading = true
                    await this.artikelAppApi.get('getGPCLevelAndName').then(response => {
                        let gpc_levels = response
                        gpc_levels.data.filter((level) => {
                            if (level.level_2_code == null) {
                                this.gpc.level_1.push(level)
                                return
                            }
                            if (level.level_3_code == null) {
                                this.gpc.level_2.push(level)
                                return
                            }
                            if (level.level_4_code == null) {
                                this.gpc.level_3.push(level)
                                return
                            }
                            this.gpc.level_4.push(level)
                        })
                        this.tmpGpc = JSON.parse(JSON.stringify(this.gpc))
                    })
                    this.loading = false
                    this.gpcIsLoaded = true
                } catch (error) {
                    console.log(error)
                    let sessionInfo = localStorage.getObject("sessionInfo")
                    if (sessionInfo.FuncArtikelAppKey == null) {
                        this.newAlert(`Fehler beim Laden da kein Authentifizierungs Key vorhanden ist. `, 'error');
                    }
                    else {
                        this.newAlert(`Fehler beim Laden der GPC Level und Namen `, 'error');
                    }
                    return null
                }
            },
            /**
             * Check if the user is still logged in. Get the FuncArtikelAppKey 
             * from the response to authenticate the user
             */
            async authFlowArtikelApp() {
                try {
                    await this.artikelAppApi.get("artikelAppAuthFlow").then(
                        (data) => {
                            if (data && data.status === 200) {
                                let sessionInfo = localStorage.getObject("sessionInfo")
                                sessionInfo.FuncArtikelAppKey = (data.data.FuncArtikelAppKey)
                                localStorage.setObject("sessionInfo", sessionInfo)
                            } else {
                                this.logout();
                            }
                        },
                        (error) => {
                            console.log("error after ArtikelAppAuthFlow:");
                            console.log(error);
                            this.logout();
                            return null
                        }
                    );
                }
                catch (error) {
                    console.log("error before ArtikelAppAuthFlow:");
                    console.log(error);
                    return null
                }
            },
            /**
             * Create a alert pop up message
             * @param message 
             * @param type 
             */
            newAlert(message, type) {
                this.alertMessage = message;
                this.alertType = type;
                this.$refs.alert.newAlert();
            },
        },
        async created() {
            await this.authFlowArtikelApp()
            await this.getGPCLevels()
            this.isAuthComplete = true
        }
    }        
</script>