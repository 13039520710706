export class ProductItem {
  constructor(
    id,
    echtid,
    name,
    product_group,
    status,
    avg_price,
    last_price,
    warengruppe,
    disabled = false,
    sales_relevant,
    upselling
  ) {
    this.id = id;
    this.name = name;
    this.product_group = product_group;
    this.echtid = echtid;
    this.status = status;
    this.avg_price = avg_price;
    this.last_price = last_price;
    this.warengruppe = warengruppe;
    this.disabled = disabled;
    this.sales_relevant = sales_relevant;
    this.upselling = upselling;
  }
}
