<template>
    <v-container fluid height="calc((100vh - 0px) / 3">
        <v-row no-gutters>
            <v-col cols="12" sm="5" md="5">
                <v-row no-gutters style="height: 35px">
                    <v-col cols="12" sm="12" md="12">
                        <v-combobox :items="itemsForSearch" item-text="item_description" item-value="item_description"
                            label="Artikelname" prepend-icon="mdi-magnify" @keyup="getItemsByDescriptionForSearch"
                            @input="emitSearchDescription" height="30px" clearable v-model="itemModel" :key="renderKey" style="font-size: 11px;">
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row no-gutters style="height: 35px">
                    <v-col cols="12" sm="12" md="12">
                        <v-combobox :items="itemsForSearch" item-text="item_id" item-value="item_id"
                            label="Artikel-Id" prepend-icon="mdi-magnify" height="30px" @keyup="getItemsByIdForSearch"
                            @change="emitSearchId" clearable>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row no-gutters style="height: 35px">
                    <v-col cols="12" sm="12" md="12">
                        <v-combobox :items="itemsForSearch" item-text="item_no" item-value="item_no"
                            label="Artikelnummer" prepend-icon="mdi-magnify" @keyup="getItemsByNoForSearch"
                            @change="emitSearchNo" height="30px" clearable>
                        </v-combobox>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" sm="6" md="6">
                <div v-if="selectedTab != 0" style="margin-left: 35px;">
                    <v-text-field 
                        v-model="searchGPCLevel"
                        label="GPC-Level"
                        dense>
                    </v-text-field>
                    <div style="max-height: 100px; overflow-y: auto; font-size: 15px;">
                        <v-treeview
                            v-model="selectedItems"
                            :items="this.gpcTransform"
                            :search="searchGPCLevel"
                            item-text="title"
                            item-key="code"
                            dense
                            return-object
                            selected-color="blue"
                            open-on-click
                            selectable
                            expand-icon="mdi-chevron-down"
                            on-icon="mdi-card-search"
                            off-icon="mdi-card-search-outline"
                            indeterminate-icon="mdi-book-search-outline">
                        </v-treeview>
                    </div>
                </div>
            </v-col>
            <v-col cols="1" sm="1" md="1" class="d-flex align-center justify-center">
                <div v-if="selectedTab != 0">
                    <v-btn @click="emitSearchGPCLevel">
                        <v-icon>mdi-card-search-outline</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <PopupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </PopupAlert>
    </v-container>
</template>

<script>

import apiMixin from '@/mixins/apiMixin.js';
import PopupAlert from "@/components/popupAlert.vue"

export default {
    data() {
        return {
            alertType: 'error',
            alertMessage: '',
            select: null,
            selectedItems: [],
            searchGPCLevel: null,
            creditors: [],
            creditorParentName: '',
            gpc: {},
            gpcTransform: [],
            renderKey: 0,
            itemModel: '',
            itemsForSearch: [],
            itemNoForSearch: '',
            itemIdForSearch: '',
            itemDescriptionForSearch: '',
        }
    },

    mixins: [apiMixin],

    methods: {
        /**
         * Emit the creditor id from the selected creditor of the combobox to the ArtikelApp View
         * @param value 
         */
        emitSearchCreditor(value) {
            if (value == null) {
                this.$emit('searchCreditor', null)
                return
            }
            if (value.tbit_echt_partner_id == null) {
                this.newAlert(`Fehler beim Suchen mit dem Lieferanten. Es liegt ein CRM Fehler vor `, 'error');
                return
            }
            this.$emit('searchCreditor', value.tbit_echt_partner_id)
        },
        /**
         * Emit the item name from the selected creditor of the combobox to the ArtikelApp View
         */
        emitSearchDescription(value) {
            this.renderKey = this.renderKey + 1
            // If search is empty
            if (value == null) {
                this.$emit('searchDescription', null)
                this.itemsForSearch = []
                return
            }
            // If search is user input
            if (value.item_description == null) {
                this.$emit('searchDescription', value)
                this.itemsForSearch = []
                return
            }
            // If search is selected from the combobox
            if (value != null) {
                this.$emit('searchDescription', value.item_description)
                this.itemsForSearch = []
            }
        },
        /**
         * Emit the item id from the selected creditor of the combobox to the ArtikelApp View
         * @param value 
         */
        emitSearchNo(value) {
            let item_ids = []
            this.itemsForSearch.forEach(element => {
                if (element.item_no == value.item_no) {
                    item_ids.push(element.item_no)
                }
            });
            if (value != null) {
                this.$emit('searchIds', item_ids)
                this.itemsForSearch = []
                return
            }
            this.$emit('searchIds', null)
            this.itemsForSearch = []
        },

        /**
         * Emit the item id from the selected item of the combobox to the ArtikelApp View
         * @param value 
         */
        emitSearchId(value) {
            if (value != null) {
                this.$emit('searchId', value.item_id)
                return
            }
            this.$emit('searchId', null)
        },


        /**
         * Emit the GPC Level from the selected creditor of the treeview to the ArtikelApp View
         */
        emitSearchGPCLevel() {
            let gpcIds = []
            this.selectedItems.forEach(element => {
                gpcIds.push(element.code)
            });
            this.$emit('searchGPCIds', gpcIds)
        },
        /**
         * Get the item based of the description if user input characters into the combobox
         * @param value 
         */
        async getItemsByDescriptionForSearch(value) {
            this.itemDescriptionForSearch = value.srcElement._value
            if (this.itemDescriptionForSearch != '') {
                try {
                    await this.artikelAppApi.get('getItemsForDescriptionSearch?item_description=' + this.itemDescriptionForSearch).then(response => {
                        this.itemsForSearch = response.data
                    })
                } catch (error) {
                    console.log(error)
                    this.newAlert(`Fehler beim Suchen von Artikel über den Artikelnamen `, 'error');
                }
            }
        },
        
        /**
         * Get the item id based of the id if user input characters into the combobox
         * @param value 
         */
        async getItemsByIdForSearch(value) {
            this.itemIdForSearch = value.srcElement._value
            if (this.itemIdForSearch != '') {
                try {
                    await this.artikelAppApi.get('getItemsForIdSearch?item_id=' + this.itemIdForSearch).then(response => {
                        this.itemsForSearch = response.data
                    })
                } catch (error) {
                    console.log(error)
                    this.newAlert(`Fehler beim Suchen von Artikel über die Artikel Id`, 'error')
                }
            }
        },


        /**
         * Get the item based of the id if user input characters into the combobox
         * @param value 
         */
        async getItemsByNoForSearch(value) {
            this.itemNoForSearch = value.srcElement._value
            try {
                await this.artikelAppApi.get('getItemsForNoSearch?item_no=' + this.itemNoForSearch).then(response => {
                    this.itemsForSearch = response.data
                })
            } catch (error) {
                console.log(error)
                this.newAlert(`Fehler beim Suchen von Artikel über die SAP-Nummer `, 'error');
            }
        },
        /**
         * Create a alert pop up message
         * @param message 
         * @param type 
         */
        newAlert(message, type) {
            this.alertMessage = message;
            this.alertType = type;
            this.$refs.alert.newAlert();
        },
        /**
         * Get the GPC Levels
         */
        async getGPCLevels() {
            try {
                this.loading = true
                await this.artikelAppApi.get('getGPCLevelAndName').then(response => {
                    this.gpc = response.data
                    this.transformGPC()
                })
            } catch (error) {
                console.log(error)
                this.newAlert(`Fehler beim Suchen von GPC-Level `, 'error');
            }
            return true
        },
        /**
         * Transform the GPC Levels for the treeview
         */
        async transformGPC() {
            this.gpc.forEach(item => {
                if (item.level_4_code == null) {return}
                let level1 = this.gpcTransform.find(l1 => l1.code === item.level_1_code);
                if (!level1) {
                    level1 = {
                        code: item.level_1_code,
                        title: item.level_1_title,
                        children: []
                    };
                    this.gpcTransform.push(level1);
                }

                let level2 = level1.children.find(l2 => l2.code === item.level_2_code);
                    if (!level2) {
                    level2 = {
                        code: item.level_2_code,
                        title: item.level_2_title,
                        children: []
                    };
                    level1.children.push(level2);
                }

                let level3 = level2.children.find(l3 => l3.code === item.level_3_code);
                if (!level3) {
                    level3 = {
                        code: item.level_3_code,
                        title: item.level_3_title,
                        children: []
                    };
                    level2.children.push(level3);
                }

                if (item.level_4_code) {
                    level3.children.push({
                        code: item.level_4_code,
                        title: item.level_4_title
                    });
                }
            })
        }
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        selectedTab: {
            type: Number,
            default: 0
        },  
        isAuthComplete: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    watch: {
        isAuthComplete: async function() {
            if (this.isAuthComplete) {
                await this.getGPCLevels();
            }
        }
    },
    components: {
        PopupAlert
    },
}
</script>
