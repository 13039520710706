<template>
  <div>
    <v-row align="center" justify="space-between" class="weather-toolbar">
      <v-col v-if="active" cols="auto" class="weather-info">
        <div class="text-with-icon">
          <span class="weather-text">{{ this.currentWeather.name }}</span>
          <v-icon class="weather-icon">mdi-map-marker</v-icon>
          <span class="pipe">|</span>
          <span class="weather-text"
            >{{ Math.round(this.currentWeather.main_temp * 2) / 2 }}
            &#x2103;
          </span>
          <v-icon class="weather-icon">mdi-thermometer</v-icon>
          <span class="pipe">|</span>
          <span class="weather-text"
            >{{ Math.round(this.currentWeather.wind_speed) }} km/h</span
          >
          <v-icon class="weather-icon">mdi-weather-windy</v-icon>
          <span class="pipe">|</span>
          <span class="weather-text"
            >{{ this.currentWeather.clouds_all }}%</span
          >
          <v-icon class="weather-icon">mdi-clouds</v-icon>
        </div>
      </v-col>
      <v-col v-else cols="auto">
        <div>
          <v-icon>mdi-cloud-remove</v-icon>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiWeatherService from "../../services/api/apiWeatherService";
export default {
  props: {
    SelectedGroup: Number,
    SelectedGroups: Array,
  },
  data() {
    return {
      active: false,
      currentWeather: {},
      forecastWeather: {},
    };
  },
  watch: {
    SelectedGroup(newVal) {
      if (newVal) {
        this.$log.debug("[Weather] Fetch new Weather data for: ", newVal);
        apiWeatherService.GetCurrentWeatherById(newVal).then((response) => {
          this.currentWeather = response;
        });
        this.$log.debug("[Weather] Current Weather: ", this.currentWeather);
      }
    },
    SelectedGroups(newVal) {
      if (newVal) {
        this.handleWeatherStatus(newVal);
      }
    },
  },
  methods: {
    handleWeatherStatus(newVal) {
      if (newVal.length != 1) {
        this.active = false;
        this.$log.debug("[Weather] No Weather data for: ", newVal);
        return;
      }
      this.active = true;
      this.$log.debug("[Weather] Fetch new Weather data for: ", newVal);
    },
  },
};
</script>

<style scoped>
.weather-toolbar {
  padding: 10px;
}

.weather-info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.text-with-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.weather-text {
  font-size: 16px;
  line-height: 1;
}

.weather-icon {
  font-size: 18px;
  vertical-align: middle;
}

.pipe {
  color: #b0bec5;
  font-size: 16px;
  line-height: 1;
}
</style>
