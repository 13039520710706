<template>
  <v-container fluid v-if="isLoggedIn == true">
    <v-row>
      <v-col cols="12">
        <v-card class="pa-5 white--text" style="background-color: #172e35">
          <v-row align="center">
            <v-col cols="auto">
              <h1 class="text-h4 font-weight-bold">Willkommen,</h1>
            </v-col>
            <v-col cols="auto">
              <h1 class="text-h4 font-weight-bold">{{ name }}</h1>
            </v-col>
          </v-row>
          <p class="text-h6 mt-2">
            Ihr Dashboard ist bereit. Alle wichtigen Informationen für den
            heutigen Tag stehen für Sie bereit.
          </p>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card class="pa-5" v-if="this.active" height="100%">
          <h2 class="text-center mb-3">Temperaturprognose</h2>
          <v-sparkline
            :value="temperatures"
            color="blue"
            line-width="3"
            :smooth="20"
            :fill="false"
            :gradient="['#ff622e', '#b84926']"
            auto-draw
          ></v-sparkline>
          <v-row class="mt-4" justify="space-between">
            <v-col
              v-for="(temp, index) in temperatures"
              :key="index"
              class="text-center"
            >
              <span class="font-weight-bold">{{ formattedDates[index] }}</span>
              <br />
              <span>{{ Math.round(temp * 2) / 2 }} °C</span>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-5" v-else height="100%">
          <h2 class="text-center mb-3">Temperaturprognose</h2>
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <v-icon size="100">mdi-cloud-remove</v-icon>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="8">
        <v-card
          class="pa-5 d-flex flex-column"
          style="display: flex"
          height="100%"
        >
          <h2 class="text-center mb-3" style="margin-bottom: 20px">
            Neuigkeiten
          </h2>

          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex-grow: 1;
              gap: 20px;
            "
          >
            <p style="font-size: 16px; line-height: 1.5; text-align: center">
              Bleiben Sie auf dem Laufenden mit unseren neuesten Updates und
              Entwicklungen. Erfahren Sie mehr über neue Features und
              Möglichkeiten für Ihr Geschäft.
            </p>

            <div class="text-center">
              <v-btn
                color="primary"
                outlined
                href="https://echt-gastropartner.de"
                target="_blank"
              >
                Mehr erfahren
              </v-btn>
            </div>
          </div>
        </v-card></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import apiWeatherService from "../services/api/apiWeatherService";

export default {
  name: "home-view",
  mounted() {
    const sessionInfo = localStorage.getObject("sessionInfo");
    if (sessionInfo != null) {
      this.name = sessionInfo.KeyCloakParam.KeyCloakUserInfo.LoginName;
      this.$emit("updateCount");
    }
    this.$log.debug("[HomeView] Mounted");
    this.$log.debug(
      "[HomeView] Fetch new Weather data for: " + this.SelectedGroup
    );
    this.getForecast();
    this.$log.debug("[HomeView] Forecast Weather: ", this.forecastWeather);
  },
  watch: {
    SelectedGroup(newVal) {
      if (newVal) {
        this.$log.debug("[Weather] Fetch new Weather data for: ", newVal);
        this.getForecast();
        this.$log.debug("[Weather] Forecast Weather: ", this.currentWeather);
      }
    },
    SelectedGroups(newVal) {
      if (newVal) {
        this.handleWeatherStatus(newVal);
      }
    },
  },
  props: {
    isLoggedIn: Boolean,
    SelectedGroup: Number,
    SelectedGroups: Array,
  },
  data: () => ({
    name: "",
    forecastData: [],
    active: false,
  }),
  methods: {
    getForecast() {
      apiWeatherService
        .GetWeatherForecastById(this.SelectedGroup)
        .then((response) => {
          let groupedByDay = response.reduce((acc, item) => {
            let date = item.dt_txt.split(" ")[0]; // Nur das Datum
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(item.main_temp);
            return acc;
          }, {});
          let forecastData = Object.entries(groupedByDay).map(
            ([date, temps]) => {
              let avgTemp =
                temps.reduce((sum, temp) => sum + parseFloat(temp), 0) /
                temps.length;
              return { dt_txt: date, main_temp: avgTemp };
            }
          );
          this.forecastData = [...forecastData];
          if (this.forecastData.length == 0) {this.active = false; return;}
          this.active = true;
        });
    },
    logout() {
      localStorage.removeItem("sessionInfo");
      window.location.reload();
    },
    handleWeatherStatus(newVal) {
      if (newVal.length != 1) {
        this.active = false;
        this.$log.debug("[Weather] No Weather data for: ", newVal);
        return;
      }
      this.active = true;
      this.$log.debug("[Weather] Fetch new Weather data for: ", newVal);
    },
  },
  computed: {
    temperatures() {
      return this.forecastData.map((item) => item.main_temp);
    },
    formattedDates() {
      return this.forecastData.map((item) =>
        new Date(item.dt_txt).toLocaleString("de-DE", {
          weekday: "short",
        })
      );
    },
  },
};
</script>
