<template>
  <v-container fluid v-if="isLoggedIn">
    <h3 :class="styles['margin-top']">Partner App</h3>
    <v-tabs v-model="tab">
      <v-tab v-if="isEditor"> Stammdaten </v-tab>
      <v-tab v-if="isEditor"> Verbinden </v-tab>
      <v-tab v-if="isEditor" :disabled="tab !== 2"> Lieferantendetails </v-tab>
      <v-tab v-else> Betrachten </v-tab>
    </v-tabs>
    <!-- Komponenten -->
    <PartnerMasterData v-if="tab === 0 && isEditor"></PartnerMasterData>
    <PartnerMerge v-if="tab === 1 && isEditor"></PartnerMerge>
    <PartnerDetails
      v-if="tab === 2 && isEditor"
      :echtPartnerId="detailPartnerId"
      @updatePartner="handleTabChange(0)"
    />
    <PartnerViewer v-if="tab === 0 && !isEditor"></PartnerViewer>
  </v-container>
</template>
<script>
import PartnerMasterData from "@/components/internal/partnerApp/PartnerMasterData.vue";
import PartnerDetails from "@/components/internal/partnerApp/PartnerDetails.vue";
import PartnerMerge from "@/components/internal/partnerApp/PartnerMerge.vue";
import PartnerViewer from "@/components/internal/partnerApp/PartnerViewer.vue";

import styles from "@/styles/internal/PartnerAppView.module.scss";

import UIService from "@/services/UIService";
import UserService from "@/services/UserService";

const emitter = require("tiny-emitter/instance");

export default {
  name: "partner-app-view",

  props: {
    isLoggedIn: Boolean,
    userService: {
      type: Object,
      default: () => new UserService(),
    },
    uiService: {
      type: Object,
      default: () => new UIService(),
    },
  },
  data: () => ({
    name: "",
    tab: 0,
    detailPartnerId: null,
    isEditor: false,
    styles,
  }),
  components: {
    PartnerMasterData,
    PartnerDetails,
    PartnerMerge,
    PartnerViewer,
  },
  created() {
    const sessionInfo = localStorage.getObject("sessionInfo");
    if (sessionInfo != null) {
      this.name = sessionInfo.KeyCloakParam.KeyCloakUserInfo.LoginName;
    }

    this.checkIfUserIsEditor();

    emitter.on("editParentPartner", async (editParentPartnerId) => {
      console.log("editParentPartner", editParentPartnerId);
      this.detailPartnerId = editParentPartnerId;
      this.tab = 2;
    });
  },
  methods: {
    handleTabChange(tab) {
      this.tab = this.uiService.changeTab(tab);
    },
    checkIfUserIsEditor() {
      this.isEditor = this.userService.checkUserRole("partner_app_editor");
    },
  },
  watch: {
    tab(newTab) {
      if (newTab === 0) {
        emitter.emit("refreshParent");
      }
    },
  },
};
</script>
