/**
 * @class
 * @classdesc
 */
export default class UIService {
  constructor(logger = console.log) {
    this.logger = logger;
  }

  /**
   * @param {number} tab
   * @returns {number}
   */
  changeTab(tab) {
    return tab;
  }
}
